import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context/ApiProvider';

import dayjs from 'dayjs'
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Iconos
import { IoIosCalendar } from "react-icons/io";
import AlertError from '../../Components/Alerts/AlertError';
import { CiWarning } from "react-icons/ci";
import { IoIosWarning } from "react-icons/io";

dayjs.extend(utc);
dayjs.extend(timezone);


const ReagendarPage = () => {
    
    const {getMeetingTokenProvider, getInfoDateCalendarProvider, eventCalendar, getMeetingTokenProvisionalProvider, getViweHoursProvider, horas, patchReagendarClienteProvider, dispatchEventCalendar} = useAppContext();
    
    const navigate = useNavigate();

    const { code } = useParams();
    const [nombre, setnombre] = useState('')
    const [psychic, setPsychic] = useState('')
    const [psychic_id, setPsychic_id] = useState(0)
    const [hora, setHora] = useState('')
    const [fecha, setFecha] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [queryName, setQueryName] = useState('')
    const [limite, setLimite] = useState(50)
    const [fechaSelect, setFechaSelect] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    const [eventSelect, seteventSelect] = useState('')
    const [disabledButton, setdisabledButton] = useState(false)
    const [availableId, setAvailableId] = useState('')
    const [error, setError] = useState(false);
    const [mensajeErrorToken, setmensajeErrorToken] = useState('')
    const [errorToken, seterrorToken] = useState(false)
    const [errorMensaje, setErrorMensaje] = useState('')

    useEffect(() => {
        const getMeeting = async() => {
            const resp = await getMeetingTokenProvisionalProvider(code);

            if(resp.error){
                if(resp.error.response.status === 400){//
                    setmensajeErrorToken(`${resp.error.response.data.message} `);
                    seterrorToken(true);
                    return;
                }
            }
            const response = await getMeetingTokenProvider(code);
            setnombre(response.user.full_name);
            setHora(dayjs(response.time_user).format('h:mm A'));
            setFecha(dayjs(response.time_user).locale('es').format('DD [de] MMMM [de] YYYY'));
            setPsychic(response.psychic.stage_name);
            setPsychic_id(response.psychic.id);
            setQueryName(response.meeting.query_name);
            setAvailableId(response.meeting.id);
            const firstDayOfMonth = dayjs().add(48, 'hour').format('YYYY-MM-DD');
            const zonaHoraria = dayjs.tz.guess();
            await getInfoDateCalendarProvider(firstDayOfMonth, response.psychic.id, currentPage, zonaHoraria, limite);
            setIsLoading(false);
        };
        getMeeting();
    }, []);

    const handleOnchangeHora = (event) => {
        seteventSelect(event.target.value)
      }


    const handleSelectChange = async(event) => {
        setFechaSelect(event.target.value);
        setIsLoading(true);
        const zonaHoraria = dayjs.tz.guess();
        const resp = await getViweHoursProvider(event.target.value, psychic_id, zonaHoraria);
        setIsLoading(false);
    };

    const handleEnviarEvento = async(e) => {
        e.preventDefault();

        if(eventSelect === ''){
           setError(true);
           setErrorMensaje('Debe seleccionar una hora valida');
           return
        }
        setdisabledButton(true);
        const horasSelected =  horas.find(hora => hora.id === parseInt(eventSelect))
  
         const data = {
          'new_available_time_id' : parseInt(eventSelect),
        }
        const resp = await patchReagendarClienteProvider(data, availableId);
        if(resp.statusText === 'OK'){

          dispatchEventCalendar({
            type:'ADD_SELECTED_EVENT',
            value : horasSelected
          });
          setdisabledButton(false);
          navigate('/reagendar/informacion');
        } else{
            setdisabledButton(false);
            setErrorMensaje('Ocurrio un problema al reagendar, consulte con el administrador');
            setError(true);
        }
    };
    
  return (
    <div className="py-2 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">


            { errorToken === false ? ( 
                    <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1'>  
                        <div className='mb-5'>
                            <h2 className="text-2xl tracking-tight font-medium text-gray-800">
                                Información de la consulta actual N° {code}
                            </h2>
                            <h4>{nombre && nombre}</h4>
                            <h4>Consulta con {psychic && psychic}</h4>
                            <h4>{fecha} a las {hora}</h4>
                            <h4 className='font-medium'>{queryName}</h4>
                        </div>

                        <form onSubmit={handleEnviarEvento}>
                            <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1 shadow-md border border-gray-100 rounded-md p-3 mb-3">
                                <div className=" text-gray-700 pl-5 pt-2 rounded-t-md h-auto">
                                    <h6 className="text-lg font-bold">Fechas disponibles para reagendar</h6>
                                </div>

                                <div className="pl-5 bg-white rounded-sm">
                                    <p>Selecciona la fecha</p>
                                    <select className="mt-2 border border-gray-300 rounded-md px-3 py-2 text-sm  w-full lg:w-1/2"
                                            value={fechaSelect}
                                            onChange={handleSelectChange}>
                                    {eventCalendar?.data && Object.entries(eventCalendar.data).map(([date, {title, day_es}], index) => (
                                        <option value={date}>{title}</option>
                                        ))
                                    }
                                    </select>
                                </div>

                                <div className="mt-5 p-5 bg-white rounded-sm grid grid-col-4 lg:grid-cols-4 sm:grid-cols-1">

                                    {
                                        isLoading ? (  <div className='flex  items-center justify-center mb-5'><span>Cargando información....</span></div> ) : (
                                        horas.map((item, index) => (
                                            <span key={item.id} className="bg-white text-gray-700 text-xs font-medium me-2 rounded p-2 border-2 border-gray-200 mb-1">
                                                <div className="flex items-center">
                                                    <input id="default-radio-1" type="radio" value={item.id} name="default-radio" onChange={handleOnchangeHora} 
                                                    className=" text-amber-600 bg-gray-100 border-gray-300 focus:ring-amber-500 focus:ring-2 "/>
                                                    <label for="default-radio-1" className="ms-2 text-sm font-medium dark:text-gray-300">{item.startHour}</label>
                                                </div>
                                            </span>
                                        ))
                                        )
                                    }
                                    
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1  rounded-md p-1">
                            
                                <button 
                                    disabled={disabledButton ? 'disabled':''}
                                    className={`  ${disabledButton ? 'bg-blue-700 cursor-not-allowed':'bg-blue-700'} hover:bg-blue-800 text-white 
                                    font-medium py-2 px-3 rounded flex items-center justify-center space-x-2 w-full lg:w-1/4`}>
                                    {!disabledButton ? 'Reagendar' : 'Espere un momento'} <IoIosCalendar className='ml-3 text-lg' />
                            </button>

                            </div>
                            { error ? (<AlertError mensaje={errorMensaje} titulo="Ocurrio un error" />) : false}

                        </form>

                    </div>
                ) : 
                (   <div className="flex  items-center justify-left bg-blue-50 rounded-md p-3.5">
                        <IoIosWarning  className="text-blue-400 text-3xl mr-3" />
                        <p className="text-blue-800 text-lg font-medium">Lo sentimos, {mensajeErrorToken}</p>
                    </div>
                )
            }


        </div>
    </div>
  )
}

export default ReagendarPage