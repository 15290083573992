import React from 'react'
import { parsePhoneNumber  } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css' 

const PhoneNumber = ({phone, setPhone}) => {
  return (
    <div>
         <PhoneInput
                inputClass='mb-5 text-gray-700 font-medium border-2 border-red-600 bg-red-600 w-96 shadow-xl'
                country="us"
                value={phone}
                onChange={setPhone}
                inputProps={{
                  required: true,
                }}
                enableSearch
                inputStyle={{ width:'90%', borderColor:'#cbd5e1', height: '40px', fontSize:'16px' }}
                buttonStyle={{ 
                      backgroundColor: '#fff', 
                      borderLeftColor:'#cbd5e1', 
                      borderRightColor:'#fff', 
                      borderTopColor:'#cbd5e1', 
                      borderBottomColor:'#cbd5e1'
                     }}
                buttonProps={{ style: { height: '100%' } }}
              />
    </div>
  )
}

export default PhoneNumber