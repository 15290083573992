import React from 'react';
import { useAppContext } from '../../Context/ApiProvider';
import GrupoOpcionesLI from './GrupoOpcionesLI';

const GrupoNavegacion = () => {

  return (
    <div>
         <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-700   rounded-lg 
                                  sm:text-base  sm:p-4 sm:space-x-4 rtl:space-x-reverse">
            
            <GrupoOpcionesLI nombre="Psíquico" numOpcion="1" />
            <GrupoOpcionesLI nombre="Tipo de consulta" numOpcion="2" />
            <GrupoOpcionesLI nombre="Hora" numOpcion="3" />
            <GrupoOpcionesLI nombre="Verificación" numOpcion="4" />
            <GrupoOpcionesLI nombre="Confirmación" numOpcion="5" />
        </ol>
    </div>
  )
}

export default GrupoNavegacion