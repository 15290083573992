import React from 'react'

export const Radio = ({id, value, name, change, checked_}) => {
  return (
    <div>
       <input  id={id}
                type="radio" 
                value={value}
                name={name}
                checked={checked_}
                onChange={change}
                className="w-4 h-4 text-purple-600 bg-purple-100 border-purple-500 focus:ring-purple-800 focus:ring-2 " />
    </div>
  )
}
