import React from 'react'

const GroupCardSkeleton = () => {
  return (
    <>
    <div className="flex flex-col items-center rounded-lg md:flex-row md:max-w-xl bg-gray-100 hover:bg-gray-100 cursor-pointer">
        <div className="w-full h-96 md:w-48 md:h-auto md:rounded-none md:rounded-s-lg bg-gray-300 animate-pulse"></div>
        <div className="flex flex-col justify-between p-4 leading-normal">
            <div className="mb-2 text-2xl font-bold tracking-tight text-gray-400 bg-gray-300 h-8 w-48 animate-pulse"></div>
            <div className="mb-3 font-normal text-gray-400 bg-gray-300 h-4 w-48 animate-pulse"></div>
        </div>
    </div>

    <div className="flex flex-col items-center rounded-lg md:flex-row md:max-w-xl bg-gray-100 hover:bg-gray-100 cursor-pointer">
        <div className="w-full h-96 md:w-48 md:h-auto md:rounded-none md:rounded-s-lg bg-gray-300 animate-pulse"></div>
        <div className="flex flex-col justify-between p-4 leading-normal">
            <div className="mb-2 text-2xl font-bold tracking-tight text-gray-400 bg-gray-300 h-8 w-48 animate-pulse"></div>
            <div className="mb-3 font-normal text-gray-400 bg-gray-300 h-4 w-48 animate-pulse"></div>
        </div>
    </div>
    </>
    
  )
}

export default GroupCardSkeleton