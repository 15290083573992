import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../Context/ApiProvider'

import dayjs from 'dayjs'
import 'dayjs/locale/es';
import { useNavigate } from 'react-router-dom';


const ReagendarInfoPage = () => {
    
  const {UserReagendado, handleCerrarSession, eventSelected} = useAppContext();
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const [fecha, setfecha] = useState()

  useEffect(() => {
    if( token === undefined || token === '' || token === null || token === null){
        navigate('/');
    }else{
        setfecha(dayjs(eventSelected.date).locale('es').format('DD [de] MMMM [de] YYYY'));
    }
  }, [])
  

  const onCLickCerrar = (event) => {
    sessionStorage.removeItem('token');
    handleCerrarSession();

  }

  return (
    <div className="py-2 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">
            <div>
                <h2 className="text-2xl tracking-tight font-medium text-gray-800">
                    Información de consulta reagendada
                </h2>
                <h4>{UserReagendado.user && UserReagendado.user.full_name}</h4>
                <h4>Consulta con {UserReagendado.psychic && UserReagendado.psychic.forename} {UserReagendado.psychic && UserReagendado.psychic.surname} </h4>
                <h4>Reagendada para el día {fecha && fecha} a las {eventSelected.startHour && eventSelected.startHour}</h4>
                <h4 className='font-medium'>{UserReagendado.meeting && UserReagendado.meeting.queryName}</h4>
            </div>
            <div>
                <h2 className='text-lg font-medium text-gray-700'>Nota importante: Se le enviará la información a su correo electronico y a su telefono por medio de mensaje de texto.</h2>
                <button type="button"
                        onClick={onCLickCerrar}
                        class="text-white bg-gradient-purple  hover:bg-purple-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none mt-4">
                    Ir a la pagina principal
                </button>
            </div>
        </div>
    </div>
  )
}

export default ReagendarInfoPage