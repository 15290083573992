import React, { useEffect, useState } from 'react'
import InputText from '../Inputs/InputText'
import { Buttons } from '../Button/Buttons';
import ButtonTrasnparent from '../Button/ButtonTrasnparent';
import { useAppContext } from '../../Context/ApiProvider';
import AlertError from '../Alerts/AlertError';
import dayjs from 'dayjs';

const ModalUpdateClientComponent = ({ isOpen, onClose }) => {

    const { patchUpdateClientProvider, infoUsers, dispatchUser} = useAppContext();

    const [email, setEmail] = useState(infoUsers.email);
    const [errorEmail, setErrorEmail] = useState(false);

    const [nombre, setNombre] = useState(infoUsers.firstname)
    const [errorNombre, setErrorNombre] = useState(false)

    const [apellido, setApellido] = useState(infoUsers.lastname)
    const [errorApellido, setErrorApellido] = useState(false)

    const [mes, setMes] = useState('')
    const [dia, setDia] = useState('')
    const [anio, setAnio] = useState('')

    const [disabledButton, setdisabledButton] = useState(false)
    const [errorCamposModal, setErrorCamposModal] = useState(false)
    const [mensajeError, setMensajeError] = useState('')
    const [tituloError, settituloError] = useState('');

    useEffect(() => {
    
        if(infoUsers.birthdate !== null){
            const fecha = dayjs(infoUsers.birthdate);
            setMes(fecha.month() + 1);
            setDia(fecha.date());
            setAnio(fecha.year());
        }
    }, [])
    
    

     
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleChangeNombre = (e) => {
        setNombre(e.target.value);
    }

    const handleChangeApellido = (e) => {
        setApellido(e.target.value);
    }

    const handleMesBirthday = (e) => {
        setMes(e.target.value);
    }

    const handleDiaBirthday = (e) => {
        setDia(e.target.value);
    }

    const handleAnioBirthday = (e) => {
        setAnio(e.target.value);
    }

    const handleSubmitActualizar = async(e) => {
        e.preventDefault();
        setdisabledButton(true);

        if(!nombre || !apellido || !email || !dia || !mes || !anio){
            setErrorCamposModal(true);
            setMensajeError('Los campos son obligatorios');
            settituloError('Error al enviar los datos');
            setdisabledButton(false);
        }else{
            const data = {
                firstname: nombre,
                lastname: apellido,
                email:email,
                client_id : infoUsers.id,
                full_name : `${nombre} ${apellido}`,
                month : mes,
                day : dia,
                date : `${anio}-${mes}-${dia}`,
                birthdate : `${anio}-${mes}-${dia}`
            }
            
            const resp = await patchUpdateClientProvider(data);
            
            if(resp.status == 200){
    
                const updateClient = {...infoUsers, ...data}
        
                 dispatchUser({
                    type:'UPDATE_USER',
                    value:updateClient
                })
    
                setdisabledButton(false);
                onClose();
            }else{
                setdisabledButton(false);
            }
        }
    }

    const handleEvent = () => {
        onClose();
        setErrorCamposModal(false);
        setMensajeError('');
        settituloError('');
    }

  return (
    <>
    
    { isOpen && ( <form>
        <div id="client-modal" className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative p-4 w-full max-w-lg max-h-full animate__animated animate__bounceInUp">
                <form onSubmit={handleSubmitActualizar} className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                    
                    <div className="flex flex-col items-center justify-center p-4 md:p-5 space-y-2">
                        <h3 className='font-medium'>Actualiza tus datos Personales </h3>
                        <h4 className='text-sm'>Mantener vigente tu información personal es importante para nosotros</h4>
                    </div>

                    <div className="grid gap-4 mb-3 lg:grid-cols-1 pl-5 pr-5">
                        <div>
                            <label  className="block mb-2 text-sm font-medium text-gray-800">Correo electronico</label>
                            <InputText placeholder="Ingresa un email valido"   
                                        disables={false} value={email} 
                                        change={handleChangeEmail} 
                                        error={errorEmail} />
                        </div>
                    </div>

                    <div className="grid gap-4 mb-2 lg:grid-cols-2 pl-5 pr-5 pb-3">
                        <div className=''>
                            <label  className="block mb-2 text-sm font-medium text-gray-800">Nombre</label>
                            <InputText placeholder="Ingresa un email valido"   
                                        disables={false} value={nombre} 
                                        change={handleChangeNombre} 
                                        error={errorNombre} />
                        </div>

                        <div className=''>
                            <label  className="block mb-2 text-sm font-medium text-gray-800">Apellido</label>
                            <InputText placeholder="Ingresa un email valido"   
                                        disables={false} value={apellido} 
                                        change={handleChangeApellido} 
                                        error={errorApellido} />
                        </div>
                    </div>

                    <div className="grid gap-6 mb-2 lg:grid-cols-1 pl-5 pr-5">
                    <label  className="block mb-2 text-sm font-medium text-gray-800">Fecha de cumpleaños</label>
                    </div>

                    <div className="grid gap-6 mb-2 lg:grid-cols-3 pl-5 pr-5 pb-3">
                        <div className=''>
                            <select value={dia} onChange={handleDiaBirthday}
                            className='border-gray-300  font-light text-gray-800 text-sm rounded focus:ring-cyan-700 focus:border-cyan-700'>
                                <option value="0">Seleccione día</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div className=''>
                            <select value={mes} onChange={handleMesBirthday}
                            className='border-gray-300  font-light text-gray-800 text-sm rounded focus:ring-cyan-700 focus:border-cyan-700'>
                                <option value="0">Seleccione mes</option>
                                <option value="1">Enero</option>
                                <option value="2">Febrero</option>
                                <option value="3">Marzo</option>
                                <option value="4">Abril</option>
                                <option value="5">Mayo</option>
                                <option value="6">Junio</option>
                                <option value="7">Julio</option>
                                <option value="8">Agosto</option>
                                <option value="9">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className=''>
                            <InputText placeholder="Año"   
                                        disables={false} value={anio} 
                                        change={handleAnioBirthday} 
                                        />
                        </div>
                    </div>


                    <div className="grid gap-4  lg:grid-cols-2 pl-5 pr-5 pb-3">
                        <Buttons size="w-full"  type="submit" nameEtiqueta="Actualizar información" event={handleSubmitActualizar} disableButton={disabledButton}/>
                        <ButtonTrasnparent size="w-full"  type="submit" nameEtiqueta="Actualizar información" handleEvent={handleEvent} />
                    </div>

                    <div className="grid gap-4  lg:grid-cols-1 pl-5 pr-5 pb-3">
                        {errorCamposModal ? (<AlertError   mensaje={mensajeError}
                                                titulo={tituloError}  />) : false}

                    </div>

                </form>
            </div>
        </div>
    </form>)}
    </>
  )
}

export default ModalUpdateClientComponent