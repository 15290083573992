import React from 'react'
import { Radio } from '../Inputs/Radio'

const GrupoOpcionesRadio = ({id, value, name, nombreEtiqueta, change, checked}) => {
  return (
    <div className="flex items-center mt-3 mb-3">

        <Radio id={id} value={value} name={name} change={change} checked_={checked} />
        <label  htmlFor={id} 
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> {nombreEtiqueta} </label>

    </div>
  )
}

export default GrupoOpcionesRadio