import { BrowserRouter, Route, Routes, useNavigate  } from 'react-router-dom';
import HeaderComponent from './Components/Header/HeaderComponent';
import HomePage from './Pages/Home/HomePage';
import VerifyAcountPage from './Pages/VerifyAcount/VerifyAcountPage';
import ProductWindow from './Pages/ProductWindow/ProductWindow';
import SelectPsychic from './Pages/ProductWindow/SelectPsychic/SelectPsychic';
import QueryMode from './Pages/ProductWindow/QueryMode/QueryMode';
import Schedule from './Pages/Schedule/Schedule';
import VerifyInformationClient from './Pages/VerifyInformationClient/VerifyInformationClient';
import CardInformation from './Pages/CardInformation/CardInformation';
import { ApiProvider } from './Context/ApiProvider';
import NotFoundPage from './Pages/NotFound/NotFoundPage';
import ClientPage from './Pages/Clients/ClientPage';
import { Toaster } from 'sonner';
import ViewInformation from './Pages/ViewInformation/ViewInformation';
import Footer from './Components/Footer/Footer';
import LinkPaginaMostrarInformacion from './Pages/LinkPaginaMostrarInformacion/LinkPaginaMostrarInformacion';
import ReagendarPage from './Pages/ReagendarPage/ReagendarPage';
import { useEffect } from 'react';
import ReagendarInfoPage from './Pages/ReagendarPage/ReagendarInfoPage';
import fondocuerpo from './assets/fondo-cuerpo.png'

function HomeLayout({ children }) {
  return (
    <div className="antialiased bg-gray-50">
      {children}
    </div>
  );
}

function MainLayout({ children }) {
  return (
    <div className="antialiased bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${fondocuerpo})` }}>
      <HeaderComponent />
      <Toaster position="top-right" closeButton  />
      <main className=" dark:bg-gray-900" >
        {children}
      </main>
    </div>
  );
}



function App() {

  useEffect(() => {
    // Función para manejar el evento beforeunload
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('token');
    };

    // Agregar el event listener para beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <BrowserRouter>
        <ApiProvider>
      {/*  <HeaderComponent /> */}
          <Toaster position="top-right" closeButton  />
          {/* <section className="bg-white dark:bg-gray-900"> */}

          <Routes>
              <Route path="/" element={<HomeLayout><HomePage /></HomeLayout>} />
              <Route path="/verificar" element={<MainLayout><VerifyAcountPage/></MainLayout>} />
              <Route path='/create-client' element={<MainLayout><ClientPage /></MainLayout>} />
              <Route path="/select-product/*" element={<MainLayout><ProductWindow /></MainLayout>} >
                  *<Route index  element={<SelectPsychic />} />**
                  <Route path='query-mode'  element={<QueryMode />} />   
                  <Route path="horario" element={<Schedule />} />
                  <Route path="verificar-informacion" element={<VerifyInformationClient />} />
                  <Route path="card-informacion" element={<CardInformation />} />
                  <Route path="view-information" element={<ViewInformation />} />
              </Route>
              <Route path="/:code" element={<MainLayout><LinkPaginaMostrarInformacion /></MainLayout>} />
              <Route path="/reagendar/:code" element={<MainLayout><ReagendarPage /></MainLayout>} />
              <Route path="/reagendar/informacion" element={<MainLayout><ReagendarInfoPage /></MainLayout>} />
              <Route path="*" element={<MainLayout><NotFoundPage /></MainLayout>} />
          </Routes>

          {/* </section> */}
       </ApiProvider>

       <Footer />
      


    </BrowserRouter>
  )
}

export default App
