import Hero from '../../Components/HomeComponents/Hero'
import Testimonials from '../../Components/HomeComponents/Testimonials'

const HomePage = () => {
  return (
    <div className='mx-auto space-y-12 lg:space-y-10 '>
       <Hero />
       <Testimonials />
    </div>
  )
}

export default HomePage