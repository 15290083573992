import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../Context/ApiProvider';


const GrupoCard = ({nombre, texto, valueInput, src}) => {

    const navigate = useNavigate();

    const {psychis, dispatcePsychicSelected, dispatchNavegar} = useAppContext();

    const handleIrPrecios = () => {
       

        const searchPsychic =  psychis.find(psychic => psychic.id === valueInput)
        dispatcePsychicSelected({
            type: 'SELECTED_PSYCHIC',
            value: searchPsychic
        });
        const data = {
            option : '2'
        }
        dispatchNavegar({
            type:'ADD_NAVEGAR',
            value: data
        });
        navigate('/select-product/query-mode');
    }


  return (
    <div>

        <a  onClick={handleIrPrecios}
            className="flex flex-col items-center  rounded-lg  md:flex-row md:max-w-xl bg-gray-100 hover:bg-gray-100 cursor-pointer pl-1">
                 

                        <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" 
                        src={src} alt=""/>
                        <div className="flex flex-col justify-between p-4 leading-normal">
                 
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{nombre}</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {texto}
                </p>
                {/* <input type="text" value={valueCard} /> */}
            </div>
        </a>
        
    </div>
  )
}

export default GrupoCard