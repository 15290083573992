import React, { useState } from 'react'
import InputText from '../Inputs/InputText'
import { ButtonSubmit } from '../Button/Buttons';
import AlertError from '../Alerts/AlertError';
import { useAppContext } from '../../Context/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';


const FormularioComponent = () => {
    
  const {postCreateClientProvider} = useAppContext();
  const navigate = useNavigate();

  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('');
  const [correo, setCorreo] = useState('');
  const [fechaNacimiento, setfechaNacimiento] = useState('');
  const [disabledButton, setdisabledButton] = useState(false);
  const [error, setError] = useState(false);
  const [mensajeError, setMensajeError] = useState('');
  const [tituloError, setTituloError] = useState('');
    
  const handleChangeNombre = (event) => {
        setNombre(event.target.value);
  };

  const handleChangeApellido = (event) => {
    setApellido(event.target.value);
  };

  const handleChangeCorreo = (event) => {
    setCorreo(event.target.value);
  };

  const handleChangeNacimiento = (event) => {
    setfechaNacimiento(event.target.value);
  };

  const handleSubmit = async(event) => {
     event.preventDefault();
     if(!nombre || !apellido || !correo ){ 
        setError(true);
        setMensajeError('Por favor revise la información, los campos marcados con (*) son obligatorios');
        setTituloError('¡Error al enviar los datos!');
     }else{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setError(false);
        setMensajeError('');
        setTituloError('');
        if(emailRegex.test(correo)){
            setdisabledButton(true);
            const data = {
                "name": nombre,
                'lastname' : apellido,
                'email' : correo,
                'birthdate' : fechaNacimiento
            }

            
            const resp = await postCreateClientProvider(data);
            if(resp === 1){
                
                setdisabledButton(false);
                toast.success(`Cliente se creo con éxito`, {
                    style: {
                        background: '#14532d',
                        color:'#fff',
                    },
                    duration: 10000,
                });
                navigate('/select-product');
            }
            

        }else{
            setError(true);
            setMensajeError('El formato de es incorrecto, ejemplo: ejemplo@ejemplo.com asi debe ser el formato');
            setTituloError('¡Error eon el formato del correo!');
        }
     }
  };
    
  return (
    <div className="text-gray-500 sm:text-lg dark:text-gray-400 ml-5">
        
        <form onSubmit={handleSubmit}>
            <h2 className='font-bold text-lg'>Formulario de información personal</h2>
            <span className='mb-10 text-sm text-cyan-700 font-medium'>Por medio de este formulario nos puede proporcionar su datos personales</span>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6 mt-10">

                <div className="w-96">
                    <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nombre <span className='text-red-600'>*</span> </label>
                    <InputText value={nombre} placeholder="Escribe tu nombre" change={handleChangeNombre} />
                </div>

                <div className="w-96">
                    <label  className="block mb-2 text-sm font-medium text-gray-900">Apellido <span className='text-red-600'>*</span> </label>
                    <InputText value={apellido} placeholder="Escribe tu apellido" change={handleChangeApellido} />
                </div>

                <div className="w-96">
                    <label  className="block mb-2 text-sm font-medium text-gray-900">Correo electronico <span className='text-red-600'>*</span> </label>
                    <InputText value={correo} placeholder="ej: xxxx@ejemplo.com" change={handleChangeCorreo} />
                </div>

                <div className="w-96">
                    <label  className="block mb-2 text-sm font-medium text-gray-900">Fecha de nacimiento </label>
                    <InputText value={fechaNacimiento} placeholder="1998-03-24" type="date" change={handleChangeNacimiento} />
                </div>

                <div className="w-96">
                    <ButtonSubmit size="w-full" nameEtiqueta="Guardar" disableButton={disabledButton} />
                </div>

                {error ? (<AlertError mensaje={mensajeError} 
                         titulo={tituloError} />) : false }

            </div>
            
        </form>

    </div>
  )
}

export default FormularioComponent