import React, { useEffect } from 'react'
import ConfirmarInformacionComponent from '../../Components/VerifyInformationClient/ConfirmarInformacionComponent'
import InformcionCompraComponent from '../../Components/CardInformationComponents/InformcionCompraComponent'
import FormularioCardComponent from '../../Components/CardInformationComponents/FormularioCardComponent'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(`${import.meta.env.VITE_STRIPE_KEY_PUBLIC}`);

const CardInformation = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    
   if( token === undefined || token === '' || token === null){
      navigate('/');
    }else{
      
    }  
  }, []);


  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    appearance: {
      theme: 'stripe',
      rules: {
        '.Tab': {
          border: '1px solid #E0E6EB',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
        }
      }
    }
      
  };

  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6'>
        
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 gap-4">

            <InformcionCompraComponent />
            <Elements stripe={stripePromise} options={options}>
              <FormularioCardComponent />
            </Elements> 

        </div>
    </div>
  )
}

export default CardInformation