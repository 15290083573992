import React, { useEffect, useState } from 'react'
import GrupoFictureQuery from '../../../Components/ProductWindowComponent/GrupoFictureQuery';
import { useAppContext } from '../../../Context/ApiProvider';
import { useNavigate } from 'react-router-dom';
import GroupCardFictureQueySkeleton from '../../../Components/Skeletons/GroupCardFictureQueySkeleton';


const QueryMode = () => {

  const {getInfoQueryProvider, querys, psychicSelected} = useAppContext();

  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const [loanding, setloanding] = useState(true)

  useEffect(() => {

    if( token === undefined || token === '' || token === null || token === null){
      navigate('/');
    }else{
      const getInfoQuery = async () => {
           await getInfoQueryProvider(psychicSelected.id);
           setloanding(false);
      }
      getInfoQuery();
    }

    
  }, []);
  

  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6'>
        <h1 className='text-3xl text-gray-800 font-medium'>Selecciona Como quiere recibir la consulta</h1>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">

            <div>
                    <div className="grid mb-8 border border-gray-200 rounded-lg shadow-sm  md:mb-12 md:grid-cols-1 bg-white ">

                            {
                               loanding ? (
                                 <GroupCardFictureQueySkeleton />
                               ) : (
                                querys && querys.length > 0 ? (
                                  querys.map((query, index) => (
                                      <GrupoFictureQuery  key={query.id}  titulo={query.description} 
                                                          texto={query.observation}
                                                          precio={query.amount}
                                                          id={query.id}
                                      />
                                  ))
                                ) : (
                                  <p>No se encontraron datoss</p>
                                )
                               )
                            }
                    </div>

            </div>
        </div>
    </div>
  )
}

export default QueryMode