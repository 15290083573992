import React from 'react'

const NotFoundPage = () => {
  return (
    <section class="bg-white ">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
              <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-gray-600 ">404</h1>
              <p class="mb-4 text-3xl tracking-tight font-bold text-gray-700 md:text-4xl dark:text-white">No se encuentra</p>
              <p class="mb-4 text-lg font-light text-gray-500">La pagina que intenta solicitar no se encuentra </p>
              <a href="#" class="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</a>
          </div>   
      </div>
    </section>
  )
}

export default NotFoundPage