import React from 'react'
import { useAppContext } from '../../Context/ApiProvider'
import dayjs from 'dayjs'

const ViewInformation = () => {

    const {infoUsers, handleCerrarSession, querySelected, eventSelected} = useAppContext();

    const handleFinalizar = () => {
        handleCerrarSession();
    }

  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6'>
        <h1 className='text-3xl text-gray-800 font-medium'>Resumen de compra</h1>
        <div className="border-t border-gray-300 my-4"></div>

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">

            <div className="text-purple-600 sm:text-lg">
                    <h2 className='font-medium'>GRACIAS POR TU COMPRA   {infoUsers.firstname} {infoUsers.lastname} </h2>
            </div>

            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <dl className=" text-gray-900 divide-y divide-gray-200 w-100">

                <div className="flex flex-col pb-5">
                    <dt className="lg:text-lg mb-1 text-gray-900 font-bold md:text-sm ">Su cita ha sido aceptada.</dt>
                    <dd className="text-sm font-semibold">Hemos aceptado su orden.  Verifique su correo electrónico y siga las instrucciones.</dd>
                    
                </div>

                <div className="flex flex-col pb-1">
                    <dt className="lg:text-lg mb-1 text-gray-900 font-bold md:text-sm ">{querySelected.description}</dt>
                    <dd className="text-sm font-semibold">{eventSelected && dayjs(eventSelected.startDate).format('D/MMM/YYYY')} -  <span className='text-gray-700'> Hora de consulta : {dayjs(eventSelected.startDate).format('hh:mm A')} (Hora de Miami) </span></dd>
                    <dt className="lg:text-lg mb-1 text-gray-900 font-light md:text-sm ">{querySelected.observation}</dt>
                </div>

                <div className="flex flex-col pb-1">
                    <dt className="lg:text-lg mb-1 text-gray-900 font-bold md:text-sm ">Instrucciones</dt>
                    
                        <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">A tener en cuenta:</h2>
                        <ul class="w-full space-y-1 text-gray-500 list-disc list-inside text-sm">
                            <li>
                                Recibirás un correo electrónico y un mensaje de texto con un enlace para unirte a la consulta.
                            </li>
                            <li>
                                La consulta se llevará a cabo a través de la plataforma Microsoft Teams.
                            </li>
                            <li>
                                Asegúrate de tener la aplicación Microsoft Teams instalada en tu teléfono y de recibir las notificaciones de recordatorio para la consulta.
                            </li>
                        </ul>

                </div>

                </dl>
            </div>
            <button className='px-3 py-2 text-sm font-medium text-center text-white  bg-purple-600  hover:bg-purple-700 focus:ring-4 focus:outline-none 
                 focus:ring-blue-300 mt-5 rounded mr-4 w-52'
                 onClick={handleFinalizar}>Ir a la pagina principal</button>

        </div>

    </div>
  )
}

export default ViewInformation