import React, { useEffect, useState } from 'react'
import GrupoCard from '../../../Components/ProductWindowComponent/GrupoCard'
import 'animate.css/animate.min.css';
import { useAppContext } from '../../../Context/ApiProvider';
import { useNavigate } from 'react-router-dom';
import GroupCardSkeleton from '../../../Components/Skeletons/GroupCardSkeleton';
import ModalUpdateClientComponent from '../../../Components/ProductWindowComponent/ModalUpdateClientComponent';

const SelectPsychic = () => {
  
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  const { getInfoPsychicProvider, psychis} = useAppContext();
  const [loanding, setloanding] = useState(true)
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    
    if( token === undefined || token === '' || token === null){
      navigate('/');
    }else{
      const getInfoPsychic = async() => {
        const resp = await getInfoPsychicProvider();
        setloanding(false);
      }
      getInfoPsychic();
    }    
  }, []);

  const handleCerrarModal = () => {
      setModalOpen(false);
  }
  

  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6 animate__animated animate__fadeIn'>
        <h1 className='text-3xl text-gray-800 font-medium'>¿Con quién te gustaría realizar tu consulta? </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 gap-4">

          {
            loanding ? (
               <GroupCardSkeleton />
            ) : (
              psychis && psychis.length > 0 ? (
                  psychis.map((psychi, index) => (
                  <GrupoCard key={index} 
                              nombre={psychi.stage_name}
                              texto={psychi.description}
                              valueInput={psychi.id} src={psychi.img} />
              ))
              ) : (
                 <p>No se encotraron datos</p>
              )
            )

          }

        </div>
        <ModalUpdateClientComponent isOpen={modalOpen} onClose={handleCerrarModal} />
        
    </div>
  )
}

export default SelectPsychic