import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../Context/ApiProvider';
import AlertError from '../Alerts/AlertError';
import { useNavigate } from 'react-router-dom';
import {useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import visa from '../../assets/visa.png'
import amex from '../../assets/american-express.png'
import mastercard from '../../assets/Mastercard.png';
import discover from '../../assets/discover.png'
import ButtonPay from '../Button/ButtonPay';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const FormularioCardComponent = () => {
    const stripe = useStripe();
    const elements = useElements();


    const {infoUsers, 
          dispatchNavegar,
          eventSelected, 
          querySelected, 
          getSelectCustomerProvider, 
          postMeetingProvider, 
          getgetCountriesProvider, 
          countriesAPI,
          countrieSelected,
          dispatchConfirNavegar} = useAppContext();
    

    const navigate = useNavigate();

    const [disabledButton, setdisabledButton] = useState(false);
    const [error, setError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [tituloError, setTituloError] = useState('');
    const [postal, setPostal] = useState('')
    const [listCountries, setListCountries] = useState('');
    const [viewPostal, setViewPostal] = useState(false);
    const [zonaHorariaCliente, setZonaHorariaCliente] = useState(null);
    const [formData, setFormData] = useState({
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    });

    useEffect(() => {

      const getCountries = async() => {
          const resp = await getgetCountriesProvider();
          const zonaHoraria = dayjs.tz.guess();
          setZonaHorariaCliente(zonaHoraria);
      };
      getCountries();
    }, []);

    useEffect(() => {

      const getCountriesUbication = async() => {
         setListCountries(countrieSelected.country);
         if(countrieSelected.country === 'US'){
            setViewPostal(true);
         }else{
            setViewPostal(false);
         }
      };
      getCountriesUbication();
    }, [])
    

    const handleInputChange = (event) => {
      setFormData({
        ...formData,
        [event.elementType]: event,
      });
    };

    const handleChangePOstal = (event) => {
      const inputValue = event.target.value;

       if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 5) {
        setPostal(inputValue);
      }
    }

    const handleChangeCountries = (event) => {
      setListCountries(event.target.value)
      if(event.target.value === 'US'){
        setViewPostal(true);
      }else{
        setViewPostal(false);
      }
    }
  

    const handleSubmit_2 = async (event) => {
      event.preventDefault();
      if (!stripe /* || !elements */) {
          setdisabledButton(false);
          return;
      }
      
      
      const {error: submitError} = await elements.submit();
      if (submitError) {
          setdisabledButton(false);
      return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const expElement = elements.getElement(CardExpiryElement);
      const cvcElement = elements.getElement(CardCvcElement);

      let expMonth, expYear, cvc;
      if (expElement) {
        const expState = expElement._controller._state;
        expMonth = expState ? expState.expMonth : null;
        expYear = expState ? expState.expYear : null;
      }
      if (cvcElement) {
        const cvcState = cvcElement._controller._state;
        cvc = cvcState ? cvcState.value : null;
      }
      
      if(   (formData.cardNumber.complete === false || formData.cardNumber === '') 
        ||  (formData.cardExpiry.complete === false || formData.cardExpiry === '') 
        ||  (formData.cardCvc.complete === false || formData.cardCvc === '')
        ||  (listCountries === '')   ){
        setMensajeError('Debe completar los campos para procesar el pago');
        setTituloError('Campos vacios');
        setError(true);
        setdisabledButton(false);
        return
      }

      if(listCountries === 'US' && !postal){
        setMensajeError('Codigo postal se requiere para el pais de Estasdos unidos');
        setTituloError('Codigo postal');
        setError(true);
        setdisabledButton(false);
        return;
      }


        setError(false);
        setdisabledButton(true);
         const{ paymentMethod, error } = await stripe.createPaymentMethod({      
            type:'card',
            card: cardElement,
            billing_details: {
                email : infoUsers.email,
                name : infoUsers.full_name,
                exp_month: expMonth,
                exp_year: expYear,
                cvc: cvc,
                address: {
                  postal_code: postal // Código postal
                }
            }    
        });    
        if(error) { 
            setdisabledButton(false);
        }
        const dataCustomer = {
            email : infoUsers.email,
            name : infoUsers.full_name
        }
        const customer = await getSelectCustomerProvider(dataCustomer);
        const objData = {
            email : infoUsers.email,
            name : infoUsers.full_name,
            amount : querySelected.amount,
            customer: customer.data.data.customer,
            paymentMethod: paymentMethod,
            available_time_id: eventSelected.id,
            query_name : querySelected.description,
            timezone_client : zonaHorariaCliente
        }
        const resp = await postMeetingProvider(objData);
        if(resp.status === 200){
            const data = {
                option : '5'
              }
              dispatchNavegar({
                  type:'ADD_NAVEGAR',
                  value: data
              });
              dispatchConfirNavegar({
                  type:'ADD_CONFIRM_NAVEGAR',
                  value: {
                    confirm : true
                  }
              })
            navigate('/select-product/view-information');
            setdisabledButton(false);
        }else{
            setMensajeError('No se pudo procesar el pago, por valide la información ');
            setTituloError('Error al procesar el pago');
            setdisabledButton(false); 
        } 

  }

     
  return (
    
    <div className="text-gray-500 sm:text-lg dark:text-gray-400 lg:ml-5 sm:ml-0">
                
            <div className='bg-purple-100 pt-3 pb-3  pl-5 text-gray-800 border border-purple-300 font-medium rounded-t-lg flex flex-col'>
              <div className='flex lg:justify-between  pr-5 flex-col lg:flex-row'>
                <label className="text-center lg:text-left" >Información de la tarjeta </label>
                {/* {JSON.stringify(countriesAPI && countriesAPI)} */}
                <div className="flex space-x-2  items-center lg:mb-0 justify-center lg:justify-start "> {/* Agrega espacio horizontal entre las imágenes */}
                  <img src={visa} alt="visa" className="w-10 h-10" />
                  <img src={amex} alt="Segunda imagen" className="w-10 h-10" />
                  <img src={mastercard} alt="Tercera imagen" className="w-10 h-10" />
                  <img src={discover} alt="Tercera imagen" className="w-10 h-9" />
                </div>
              </div>
              <span className='text-sm font-medium  hidden sm:block'>Todas las transacciones son seguras y estan encriptadas</span>

            </div>
        <form onSubmit={handleSubmit_2} >

              <div className=' bg-gray-50 p-5  border border-gray-250'>

                <div className="grid gap-1 mb-3 lg:grid-cols-1">
                    <label className='font-medium'>Número de la tarjeta</label>
                    <CardNumberElement
                      options={{
                        style: {
                          base: {
                            fontSize: '15px',
                            color: '#18181b',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                            height: '40px',
                          },
                        },
                        showIcon: true,
                      }}
                      className='myInput bg-white border-gray-50 rounded-none'
                      onChange={handleInputChange}
                      name="cardNumber"
                    />
                </div>

                <div className="grid gap-4 mb-3 lg:grid-cols-2">
                  
                    <div>
                    <label className='font-medium'>Fecha de vencimiento</label>
                        <CardExpiryElement
                          options={{
                            style: {
                              base: {
                                fontSize: '15px',
                                color: '#424770',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                            },
                            placeholder: 'Fecha de vencimiento (MM / AA)',
                          }}
                          className='myInput bg-white mt-3'
                          onChange={handleInputChange}
                          name="cardExpiry"
                        />
                    </div>
                    <div>
                      <label className='font-medium'>Codigo de seguridad</label>
                        <CardCvcElement
                          options={{
                            style: {
                              base: {
                                fontSize: '15px',
                                color: '#424770',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                                height: '40px',
                              },
                            },
                            placeholder: 'Codigo de seguridad',
                          }}
                          className='myInput bg-white text-lg mt-3'
                          onChange={handleInputChange} 
                          name="cardCvc"
                        />
                    </div>
                </div>

                <div className="grid gap-4 mb-3 lg:grid-cols-1">
                      <div >
                        <label className='font-medium'>País</label>
                        <select className='myInput_inputs bg-white text-lg text-gray-700 mt-1 w-full rounded-md border border-gray-300' 
                        onChange={handleChangeCountries}
                        value={listCountries}>
                            {countriesAPI.map(country => (
                                  <option key={country.alpha2Code} value={country.alpha2Code}>{country.translations.es}</option>
                            ))}
                        </select>
                      </div>
                      {viewPostal && (<div>
                        <label className='font-medium'>Codigo postal</label>
                        <input type="text" 
                               className='myInput_inputs bg-white mt-1 w-full placeholder:text-slate-400 font-normal text-gray-900 rounded-md border border-gray-300' 
                               placeholder='12345'
                               onChange={handleChangePOstal}
                               value={postal}
                               maxLength={5} />
                      </div>)}
                </div>

              </div>

             
            
            <div className=''>
                <ButtonPay  size="w-full" nameEtiqueta="PROCESAR PAGO" disableButton={disabledButton}  />
            </div>

        </form> 

        {/* <form onSubmit={handleSubmit_payment}>
            <PaymentElement />
            <div className='mt-10'>
                <ButtonSubmit  size="w-full" nameEtiqueta="Pagar" />
            </div>
        </form> */}
        <div className='mt-5'>
            {error ? (<AlertError mensaje={mensajeError} 
                            titulo={tituloError} />) : false }
        </div>

    </div>
  )
}

export default FormularioCardComponent