import Formulario from './Formulario'

const VerifyAcountPage = () => {
  
  return (
    <div className='py-6 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6'>
       
       <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">

                <div className="w-100 bg-trasparent ">
                   <h1 className="lg:text-3xl font-medium leading-tight text-gray-900 sm:text-lg dark:text-white">
                    ¡Ayúdanos a verificar tu cuenta! 
                   </h1>
                   <p className='pt-3 justify-start'>
                   Proporcione su número de teléfono para validar su información. Recibirá un código de verificación, válido por 10 minutos, 
                   para completar el proceso. Mantenga el código confidencial. Si no ha solicitado este código, puede ignorar este mensaje. 
                   Si su número no está registrado, será redirigido al formulario de creación de cuenta. ¡Gracias!
                   </p>
                </div>

       </div>

       {/* Metodo de envio */ }
       <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">
           
            <Formulario />

       </div>

       {/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}

    </div>
  )
}

export default VerifyAcountPage