import React, { useEffect } from 'react'
import Calendario from '../../Components/ScheduleComponents/Calendario';
import { useAppContext } from '../../Context/ApiProvider';
import { useNavigate } from 'react-router-dom';


const Schedule = () => {

  const {eventCalendar} = useAppContext();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  useEffect(() => {

      if( token === undefined || token === '' || token === null || token === null){

          navigate('/');

      }else{
        
      }

  }, [])
  

  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6'>
        <h1 className='text-3xl text-gray-800 font-medium'>Seleccione horario de su consulta</h1>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">
             <Calendario event={eventCalendar} />
        </div>
    </div>
  )
}

export default Schedule