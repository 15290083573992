import React from 'react';
import { useAppContext } from '../../Context/ApiProvider';
import { useNavigate } from 'react-router-dom';


const GrupoOpcionesLI = ({nombre, numOpcion}) => {

    const { dispatchNavegar,  optionNav, psychicSelected, psychis, querySelected, eventSelected, ventaConfirmada} = useAppContext();
    const navigate = useNavigate();

    const handleNavegar = () => {

        const data = {
            option : numOpcion
        }
        
        switch (numOpcion) {
            case '1':
                dispatchNavegar({
                    type:'ADD_NAVEGAR',
                    value: data
                });
                navigate('/select-product');
                
            break;
            case '2':
                if(psychicSelected.length > 0 || Object.keys(psychicSelected).length > 0){
                    navigate('/select-product/query-mode');
                    dispatchNavegar({
                        type:'ADD_NAVEGAR',
                        value: data
                    });
                }
            break;
            case '3':
                if(Object.keys(querySelected).length > 0){
                    dispatchNavegar({
                        type:'ADD_NAVEGAR',
                        value: data
                    });
                    navigate('/select-product/horario');
                }
            break;
            case '4':
                if(eventSelected.length > 0 || eventSelected === true){
                    dispatchNavegar({
                        type:'ADD_NAVEGAR',
                        value: data
                    });
                    navigate('/select-product/verificar-informacion');
                }
            break;
            case '5':
                if(ventaConfirmada && ventaConfirmada.confirm === true){
                    dispatchNavegar({
                        type:'ADD_NAVEGAR',
                        value: data
                    });
                    navigate('/select-product/view-information');
                }
            break;
        
            default: navigate('/select-product');
            break;
        }
    }

  return (
    <li className={`flex items-center ${optionNav.option === numOpcion ? 'text-purple-600 text-lg' : ''}  cursor-pointer ` } onClick={handleNavegar} 
        aria-disabled={optionNav.option !== numOpcion ? 'true' : 'false'} >
        <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${optionNav.option === numOpcion ? 'border-purple-600 font-extrabold' : ''} rounded-full shrink-0 `}>
            {numOpcion}
        </span>
        <span className='hidden sm:inline-flex ms-2'>{nombre}</span> {/* <span className="hidden sm:inline-flex sm:ms-2">Info</span> */}
        <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
        </svg>
    </li>
  )
}

export default GrupoOpcionesLI