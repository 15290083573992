import React from 'react'
import { IoClose, IoCloseCircleOutline  } from "react-icons/io5";

const ButtonTrasnparent = ({handleEvent}) => {
  return (
    <div>
    <button type="button"
            onClick={handleEvent}
            className="px-3 py-2 w-full flex items-center justify-center text-sm font-light text-center text-purple-600 hover:bg-purple-700 hover:text-white focus:ring-4 
            focus:outline-none  rounded-md cursor-pointer border border-purple-600 ">

      Mis datos estan correctos
    </button>
</div>
  )
}

export default ButtonTrasnparent