import React, { useEffect, useState } from 'react'
import { dayjsLocalizer } from 'react-big-calendar';
import "dayjs/locale/es";
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../Context/ApiProvider';

import dayjs from 'dayjs'
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// Iconos
import { IoIosArrowBack, IoIosArrowForward, IoIosCalendar   } from "react-icons/io";
import AlertError from '../Alerts/AlertError';


const Calendario = ({}) => {
    
  const localizer = dayjsLocalizer(dayjs);
  const navigate = useNavigate();
  const {eventCalendar, infoUsers, psychicSelected, getInfoDateCalendarProvider,  
    horas, getSelectMeetingProvider, dispatchEventCalendar, getViweHoursProvider,
    currentpagePro, dispatchCurrentPage, dispatchNavegar} = useAppContext();


  const [selectedIndex, setSelectedIndex] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [firstDate, setFirstDate] = useState('')
  const [eventSelect, seteventSelect] = useState('')

  const [error, setError] = useState(false);
  const [disabledButton, setdisabledButton] = useState(false)
  const [isLoading, setIsLoading] = useState(true); 
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [loandingFechas, setLoandingFechas] = useState(true);
  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] = useState(false)
  

  useEffect(() => {
        const firstDayOfMonth = dayjs().add(2, 'hour').format('YYYY-MM-DD');
        const psychic = psychicSelected.id;
        const zonaHoraria = dayjs.tz.guess();
  
        const getInfoCalendar = async () => {
            await getInfoDateCalendarProvider(firstDayOfMonth, psychic, currentPage, zonaHoraria);
            setIsLoading(false);
            setLoandingFechas(false)
        };
        getInfoCalendar();

        if(currentpagePro.total_pages === currentPage){
          setIsNextButtonDisabled(false);
        }else{
          setIsNextButtonDisabled(true);
        }

        if(currentPage > 1){
          setIsPreviousButtonDisabled(true);
        }
  }, [currentPage]);
  
  const goToPreviousPage = () => {
    
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
      setLoandingFechas(true);
      setIsPreviousButtonDisabled(false);
    }else{
      setIsPreviousButtonDisabled(true);
    }
  };

  const goToNextPage = () => {
    if(currentPage < currentpagePro.total_pages){
      setCurrentPage(prevPage => prevPage + 1);
      setLoandingFechas(true)
    }else{
      setIsNextButtonDisabled(false);//
    }
  };
  
  const handleOnchangeHora = (event) => {
    seteventSelect(event.target.value)
  }

  const handleEnviarEvento = async(e) => {
      e.preventDefault();


      if(eventSelect === ''){
         setError(true);
         return
      }

      setdisabledButton(true);

      const horasSelected =  horas.find(hora => hora.id === parseInt(eventSelect))

       const data = {
        'available_time_id' : parseInt(eventSelect),
        'client_id' : infoUsers.id,
        'psychic_id' :  psychicSelected.id
      }
      const resp = await getSelectMeetingProvider(data);
      if(resp.status === 200){
        dispatchEventCalendar({
          type:'ADD_SELECTED_EVENT',
          value : horasSelected
        });
        const data = {
          option : '4'
        }
        dispatchNavegar({
            type:'ADD_NAVEGAR',
            value: data
        });
        setdisabledButton(false);
          navigate('/select-product/verificar-informacion');
      } 
  };

  const handleDateClick = async(date, index) => {

    setIsLoading(true);
    const zonaHoraria = dayjs.tz.guess();
    setSelectedIndex(index);//
    const resp = await getViweHoursProvider(date, psychicSelected.id, zonaHoraria);
    setIsLoading(false);

  }

  return (
    <div>
       
         <div className='grid gap-3 grid-col-1 bg-gray-50 shadow-4xl rounded-t-lg p-2'>
            
            <div className="flex items-center justify-center space-x-4">

                { isPreviousButtonDisabled &&  (<button onClick={goToPreviousPage}>
                  <IoIosArrowBack  className="w-6 h-6 text-gray-700" />
                </button>)}

                
                <div className="flex flex-col items-center space-y-2 md:flex-row md:space-y-0 md:space-x-2 md:w-full justify-center">
                  {loandingFechas ? ( <div className="loader">Cargando información...</div>):(
                      eventCalendar?.data && Object.entries(eventCalendar.data).map(([date, {title, day_es}], index) => (
                        <span
                          key={date}
                          className={`cursor-pointer px-3 py-1 text-sm text-center w-full md:w-auto rounded-md
                                ${index === selectedIndex ? 'bg-gray-100 text-gray-700 font-medium border-2 border-purple-500' 
                                : 'bg-gray-100 text-gray-700 shadow-md'} `}
                          onClick={() => handleDateClick(date, index)}
                        >
                           {title} <br />  <span className='justify-center font-medium'>{day_es}</span> 
                        </span>
                        
                      ))
                  )}
                </div>

                {isNextButtonDisabled && <button onClick={goToNextPage}>
                  <IoIosArrowForward  className="w-6 h-6 text-gray-700" />
                </button>}

            </div>

         </div>
         <div className='grid gap-3 grid-col-1'>
              <form className='bg-gray-50 p-5 border border-gray-100 shadow-xl rounded-b-lg'
                    onSubmit={handleEnviarEvento}>
                      
                
                {isLoading ? (  <div className='flex  items-center justify-center mb-5'><span>Cargando información....</span></div> ) : (
                
                   horas.map((item, index) => (
                    <div key={item.id} className="flex  items-center justify-center space-x-4 mb-5">
                         <div class="flex items-center mb-1">
                              <input id={item.id} type="radio" value={item.id} name="default-radio" onChange={handleOnchangeHora}
                                     class="w-4 h-4 text-purple-600 bg-gray-100 border-purple-600 focus:ring-purple-800 focus:ring-2 " />
                              <label htmlFor={item.id} class="ms-2 text-lg font-medium text-gray-700">
                                  {item.startHour}
                              </label>
                         </div>
                    </div>
                   ))
                  )}
                <div className="flex justify-center">
                  <button 
                    disabled={disabledButton ? 'disabled':''}
                    className={`  ${disabledButton ? 'bg-purple-500 cursor-not-allowed':'bg-gradient-purple'} hover:bg-purple-700 text-white font-medium py-2 px-3 rounded flex items-center justify-center space-x-2`}>
                    {!disabledButton ? 'Reservar' : 'Espere un momento'} <IoIosCalendar className='ml-3 text-lg' />
                  </button>
                </div>

                <div class="flex items-center p-4 mt-4 text-sm font-medium text-gray-600 border border-purple-300 rounded-lg bg-purple-50" role="alert">
                  <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                  </svg>
                  <span class="sr-only">Información Importante</span>
                  <div>
                    <span class="font-medium"></span> Si no encuentra disponible la fecha que desea, significa que los cupos están completos. Por favor, visite nuestra página nuevamente en otro momento para verificar si hay espacios disponibles.
                  </div>
                </div>
                
              </form>
               { error ? (<AlertError mensaje="Debe seleccionar una hora valida" titulo="Error al seleccionar una hora" />) : false} 
         </div>      
          

    </div>
  )
}

export default Calendario