import React, { useEffect, useState } from 'react'
import GrupoOpcionesRadio from '../../Components/VerifyAccountComponents/GrupoOpcionesRadio'
import { ButtonSubmit, Buttons } from '../../Components/Button/Buttons'
import ModalCodigo from './ModalCodigo'
import AlertError from '../../Components/Alerts/AlertError'
import { useAppContext } from '../../Context/ApiProvider'
import { useNavigate } from 'react-router-dom'
import PhoneNumber from '../../Components/PhoneNumber/PhoneNumber';


const Formulario = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [valueMetodoEnvio, setValueMetodoEnvio] = useState('sms')
    const [error, setError] = useState(false);
    const [disabledButton, setdisabledButton] = useState(false)
  
    const [numeroTelefono, setnumeroTelefono] = useState('');
    const [country, setCountry] = useState('')

    const [estadoCheckedSms, setEstadoCheckedSms] = useState(false)
    const [estadoCheckedCall, setEstadoCheckedCall] = useState(false)

    const {dispatchInfoTelefono, obtenerCodigoProvider, dispatchNavegar, dispatchCountrieUbication, getCountrieUbicationProvider, countrieSelected} = useAppContext();
    const navigate = useNavigate();

    const handleChangeValueMetodo = (e) => {
        setValueMetodoEnvio(e.target.value);
        if(e.target.value === 'sms'){
            setEstadoCheckedSms(true);
            setEstadoCheckedCall(false);
        }else{
            setEstadoCheckedSms(false);
            setEstadoCheckedCall(true);
        }
    }

    useEffect(() => {
        setEstadoCheckedSms(true);
        setEstadoCheckedCall(false);
    }, [])
    


    const handleVerificar = async (e) => {
        e.preventDefault();
        setdisabledButton(true);
        
        if(!valueMetodoEnvio || !numeroTelefono ){
            setError(true);
            setdisabledButton(false);
        }else{
            setError(false);
            const data = {
                'target':  '+'+numeroTelefono,
                'method' : valueMetodoEnvio
            }

            dispatchInfoTelefono({
                type: 'ADD_TELEFONO',
                value: data
             });

             const resp = await obtenerCodigoProvider(data);
             if(resp.estatus === 200){
                setModalOpen(true);
                setdisabledButton(false);

                const data = {
                    option : '1'
                }

                dispatchNavegar({
                    type:'ADD_NAVEGAR',
                    value: data
                });
             }
        }

    };

    const handleCerrarModal = () => {
        setModalOpen(false);
    }

    const handleIrPortal = () => {
        navigate('/');
    }

  return (
    <>
        <form onSubmit={handleVerificar}>
            <div className="w-100 bg-trasparent">
                <h6 className="lg:text-lg font-medium leading-tight text-gray-700 sm:text-lg" >Selecciona tu metodo preferido</h6>
            </div>
            <div className="w-100 bg-trasparent pl-5 mb-10">

                <GrupoOpcionesRadio id="sms" value="sms" name="metodoenvio" nombreEtiqueta="Mensaje de texto" change={handleChangeValueMetodo} checked={estadoCheckedSms && estadoCheckedSms} />
                <GrupoOpcionesRadio id="call" value="call" name="metodoenvio" nombreEtiqueta="LLamada télefonica" change={handleChangeValueMetodo} checked={estadoCheckedCall && estadoCheckedCall}  />

            </div>

            <div className="w-100 bg-trasparent pl-2 mb-10">

                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Por favor introduce tu número telefonico</label>
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4 w-96 mb-10">
                    <PhoneNumber phone={numeroTelefono} setPhone={setnumeroTelefono} />
                </div>

                <ButtonSubmit size="lg:w-48 md:w-full"  nameEtiqueta="Enviar codigo" disableButton={disabledButton}  />

            </div>
            {error ? (<AlertError  mensaje="Asegúrate de proporcionar la información requerida en todos los campos." 
                         titulo="Para continuar, completa todos los campos." />) : false}
        </form>
        <ModalCodigo isOpen={modalOpen} onClose={handleCerrarModal} reenvio={handleVerificar}  />
    </>
  )
}

export default Formulario