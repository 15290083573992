import React from 'react'

const GroupCardFictureQueySkeleton = () => {
  return (
    <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 
        rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e animate__animated animate__fadeIn animate__delay-2s cursor-pointer">
        <div className="animate-pulse max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
            <div className="h-6 bg-gray-200 w-2/3 mb-4 rounded-lg"></div>
            <div className="h-12 bg-gray-200 w-full mb-4 rounded-lg"></div>
            <div className="h-12 bg-gray-200 w-full mb-4 rounded-lg"></div>
        </div>
        <div className="animate-pulse flex items-center justify-center">
            <div className="h-12 bg-gray-200 w-20 rounded-lg"></div>
        </div>
    </figure>
  )
}

export default GroupCardFictureQueySkeleton