import { useState } from "react";
import RatingComponent from "../HomeComponents/RatingComponent"
import fondo from '../../assets/fondo.png'
const Testimonials = () => {

    const [currentIndex, setCurrentIndex] = useState(1);

    const testimonials = [
        "Gracias al equipo de videntes por su increíble apoyo. Cada sesión me ha aportado claridad y paz, ayudándome a navegar por los desafíos diarios con una nueva perspectiva. Realmente valoro su comprensión y consejos precisos.",
        "Siempre estoy agradecido por las lecturas compasivas y enriquecedoras que recibo. En momentos de duda, las palabras alentadoras y los insights psíquicos me han ofrecido dirección y consuelo. ¡Qué servicio tan invaluable!",
        "Las sesiones con mi consejero psíquico han sido una revelación. Sus predicciones no solo se han cumplido, sino que sus consejos me han ayudado a tomar decisiones importantes con confianza. Realmente aprecio la guía y el cuidado que me han brindado.",
        "Nunca pensé que una llamada telefónica podría cambiar mi perspectiva de vida, pero así ha sido. La precisión de sus lecturas y la profundidad de su entendimiento me han impresionado profundamente. Gracias por iluminar mi camino."
    ];

    const authors = [
        { name: "Luisa Salinas", location: "Republica Dominicana" },
        { name: "Sofía Herrera", location: "Perú" },
        { name: "Mariana Cortés", location: "México" },
        { name: "Carlos Esteban Vega", location: "Estados Unidos" }
    ];

    const handlePrev = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

return (

<section className="bg-gray-50"  >
    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-lg mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Experiencias Reales</h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">Descubre cómo nuestros servicios y productos han marcado una diferencia real en la vida de nuestros clientes. Cada testimonio aquí refleja historias personales de satisfacción y éxito, brindándote una visión genuina de la experiencia con los psiquicos del Niño Prodigio</p>
        </div> 
        

        <div className="relative h-72 lg:h-56">
            <div className="flex flex-wrap justify-center" >
                {testimonials.map((testimonial, index) => (
                <figure
                    key={index}
                    className={`mx-auto w-full max-w-screen-md ${index === currentIndex ? '' : 'hidden'}`}
                    data-carousel-item
                >
                    <blockquote>
                    <p className="text-lg font-medium text-gray-900 sm:text-2xl dark:text-white">{testimonial}</p>
                    </blockquote>
                    <div className='flex justify-center '><RatingComponent/></div>
                    <figcaption className="flex justify-center items-center mt-6 space-x-3">
                    <img className="w-6 h-6 rounded-full" src={`https://i.pravatar.cc/150?u=a03${index + 1}`} alt="profile"/>
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <div className="pr-3 font-medium text-gray-900 dark:text-white">{authors[index].name}</div>
                        <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">{authors[index].location}</div>
                    </div>
                    </figcaption>
                </figure>
                ))}
            </div>
            <div className="flex items-center justify-center mt-5 sm:mt-8">
            <button type="button" className="flex justify-center items-center mr-4 h-full cursor-pointer group focus:outline-none" onClick={handlePrev}>
                {/* Previous button icon */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <button type="button" className="flex justify-center items-center h-full cursor-pointer group focus:outline-none" onClick={handleNext}>
                {/* Next button icon */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </button>
            </div>
        </div>


    </div>
    </section>




  )
}

export default Testimonials