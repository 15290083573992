import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../Context/ApiProvider';
import dayjs from 'dayjs'
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FaRegCopy } from "react-icons/fa";
import { IoSadOutline } from 'react-icons/io5';
import { IoIosWarning } from 'react-icons/io';
dayjs.extend(utc);
dayjs.extend(timezone);

const LinkPaginaMostrarInformacion = () => {
const { code } = useParams();
const navigate = useNavigate();

const [hora, setHora] = useState('')
const [fecha, setFecha] = useState('')
const {getMeetingTokenProvider, getMeetingTokenProvisionalProvider} = useAppContext();
const [nombre, setnombre] = useState('')
const [url, setUrl] = useState('')
const [psychic, setPsychic] = useState('')
const [copiado, setCopiado] = useState(false);
const [mensajeErrorToken, setmensajeErrorToken] = useState('')
const [errorToken, seterrorToken] = useState(false)
const [query_name, setQuery_name] = useState(false)
const [querynameText, setQueryNameText] = useState('')

useEffect(() => {

    const getMeeting = async() => {

        const respuesta = await getMeetingTokenProvisionalProvider(code);

        if(respuesta.error){
            if(respuesta.error.response.status === 400){//
                setmensajeErrorToken(`${respuesta.error.response.data.message} `);
                seterrorToken(true);
                return;
            }
        }

        const resp = await getMeetingTokenProvider(code);
        setnombre(resp.user.full_name);

        const zonaHoraria = dayjs.tz.guess();
        const horaConvertida = dayjs(resp.time_user).tz(zonaHoraria);
        setHora(dayjs(resp.time_user).format('h:mm A'));
        setFecha(dayjs(resp.time_user).locale('es').format('DD [de] MMMM [de] YYYY'));

        if(resp.meeting.query_name === 'CONSULTA VIRTUAL'){

            setUrl(resp.meeting.url);
            setQuery_name(false)
            setQueryNameText(resp.meeting.query_name);
        }else{
            setUrl('https://www.google.com/maps/place/1175+Jerome+Ave,+Bronx,+NY+10452/data=!4m2!3m1!1s0x89c2f43aa42d8fbb:0x62d3e9edb7f319f3?sa=X&amp;ved=1t:242&amp;ictx=111');
            setQuery_name(true)
            setQueryNameText(resp.meeting.query_name);
        }

        setPsychic(resp.psychic.stage_name);

    }

    getMeeting();
    
}, []);

const copiarAlPortapapeles = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiado(true);
    } catch (error) {
      console.error('Error al copiar al portapapeles:', error);
    }
};

const handleReagendar = () => {
    navigate(`/reagendar/${code}`);
}

  return (
    <div className="py-2 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6">
        { errorToken === false ? ( 
            <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-1">
                <div>
                    <h2 className="tracking-tight font-medium text-gray-800 dark:text-white md:text-sm lg:text-2xl">
                        Información de la consulta N° {code}
                    </h2>
                    <h4>Nombre : {nombre && nombre}</h4>
                    <h4>Fecha y hora  : {fecha && fecha} a las {hora && hora}</h4>
                    <h4>Consulta con : {psychic && psychic}</h4>
                    <h4>Metodo : {querynameText && querynameText}</h4>
                    <div className='bg-gray-50 border border-gray-100 rounded-md p-4 mt-3'>
                        <div className="flex justify-between items-center mb-5">
                            <h4 className='mt-5 mb-3'>
                                {query_name === false ? 'Vínculo de la consulta' : 'Ubicación'}
                            </h4>
                            <button onClick={copiarAlPortapapeles} className="bg-gradient-purple  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center">
                                <FaRegCopy className="mr-1" />
                                {copiado ? 'Copiado' : 'Copiar'}
                            </button>
                        </div>
                        <span>
                            {query_name === true ? '1175 Jerome Ave Bronx, NY 10452' : ''}
                        </span>
                        <a href={url} className="text-blue-400" target="_blank" rel="noopener noreferrer" style={{ maxWidth: '90%', display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {url && url}
                        </a>
                    </div>
                </div>
                <div className='mt-5'>
                    <h2 className='font-medium mb-2'>¿Necesita reprogramar su cita? Haga clic en el botón a continuación para acceder a la página de reagendar.</h2>
                    <button type="button" 
                            className="text-purple-700 hover:text-white  hover:bg-purple-800 border border-purple-700  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none"
                            onClick={handleReagendar}
                            >
                        Quiero reagendar mi cita
                    </button>
                </div>
            </div>):
            (     <div className="flex  items-center justify-left bg-blue-50 rounded-md p-3.5">
                    <IoIosWarning  className="text-blue-400 text-3xl mr-3" />
                    <p className="text-blue-800 text-xl font-medium">Lo sentimos, {mensajeErrorToken}</p>
                </div>
            )
        }
    </div>
  )
}

export default LinkPaginaMostrarInformacion