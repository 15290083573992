import React from 'react'
import { useAppContext } from '../../Context/ApiProvider'


const InformcionCompraComponent = () => {

  const {psychicSelected, querySelected, eventSelected, infoUsers, infoTelefono} = useAppContext();

  const formatPhoneNumber = () =>{
   
   const cleaned = ('' + infoTelefono.target).replace(/\D/g, '');
   
      if (cleaned.length === 10) {
         const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
         if (match) {
            return `+57 (${match[1]}) ${match[2]} ${match[3]}`;
         }
      } else if (cleaned.length === 12 && cleaned.startsWith('57')) {
      
         const match = cleaned.match(/^(57)(\d{3})(\d{3})(\d{4})$/);
         if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]} ${match[4]}`;
         }
      }
      
      return phoneNumber;
  }

  return (
    
    <div className="text-gray-700 sm:text-xl ">
                    
        <div class="flex items-start gap-4 ">
            <img class="w-20 h-20 rounded" src={psychicSelected && psychicSelected.img} alt="" />
            <div class="font-medium dark:text-white flex flex-col w-full">
                <div className="w-full flex justify-between">
                    <span>{querySelected && querySelected.description}</span> 
                    <span className='font-bold text-gray-700'>${querySelected && querySelected.amount}</span> 
                </div>
                <div className="w-full flex lg:justify-between sm:justify-end  mt-5 sm:text-right lg:text-right">
                    <span className='text-gray-700 text-sm'>Cliente</span>
                    <span className='text-sm'>{infoUsers && infoUsers.full_name}</span>
                 </div>
                 <div className="w-full flex justify-between  mt-5">
                    <span className='text-gray-700 text-sm'>Telefono</span>
                    <span className='text-sm'>{infoUsers && infoTelefono.target}</span>
                 </div>
                 <div className="w-full flex justify-between  mt-5">
                    <span className='text-gray-700 text-sm'>Correo</span>
                    <span className='text-sm'>{infoUsers && infoUsers.email}</span>
                 </div>
                 <hr className="w-full border-t border-gray-300 mt-5" />
                  <div className='text-sm w-full flex justify-between  mt-3'>
                    <span className='text-lg text-gray-700'>Psiquico</span>
                    {psychicSelected && psychicSelected.stage_name}
                  </div>
                  <div class="text-sm text-gray-700 font-medium">{eventSelected && eventSelected.date}</div>
                  <hr className="w-full border-t border-gray-300 mt-5" />
                 <div className="w-full flex justify-between mt-5">
                    <span className='text-gray-700'>Subtotal</span>
                    <span>${querySelected && querySelected.amount}</span>
                 </div>
                 <hr className="w-full border-t border-gray-300 mt-5" />
                 <div className="w-full flex justify-between mt-5">
                    <span className='text-gray-700'>Total a pagar hoy</span>
                    <span>${querySelected && querySelected.amount}</span>
                 </div>
            </div>
            
        </div>

    </div>

  )
}

export default InformcionCompraComponent