import React, { useState } from 'react'
import Select from 'react-select';
import Flag from 'react-world-flags'

const countries = [
    { value: 'tel:18004110112', label: '1800 411 0112', flag: 'US' },
    { value: 'tel:018005190092', label: '01 800 519 0092', flag: 'CO' },
    { value: 'tel:8299478994', label: '829 947 8994', flag: 'DO' },
    { value: 'tel:900751988', label: '900 751 988', flag: 'ES' },
    { value: 'tel:8000991173', label: '800 099 1173', flag: 'MX' },
/*     { value: 'tel:31800001495', label: '+59 318 000 01495', flag: 'EC' },
    { value: 'tel:223007115', label: '+50 223 007 115', flag: 'GT' },
    { value: 'tel:80074613', label: '+51 800 746 13', flag: 'PE' }, */
    // Agrega más países según sea necesario
  ];

  const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#f8fafc',
        borderColor: '#f1f5f9',
        '&:hover': {
            borderColor: '#cbd5e1'
        },
        boxShadow: '0 6px 10px -1px #cbd5e1, 0 2px 4px -1px #cbd5e1',
        height: '50px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
};

const SelectFlags = ({ selectedCountry, defaultCountry }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const handleChange = (option) => {
        setSelectedOption(option);
    };

    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Flag code={flag} style={{ width: 20, marginRight: 10 }} />
          {label}
        </div>
    );

  return (
    <>
    <div className='flex justify-between items-center space-x-4 w-full'>
        <h6 className='hidden sm:block text-gray-50 font-medium text-lg' >LLamanos</h6>
        <div className='w-full'>

            <Select
                defaultValue={countries.find(country => country.value === defaultCountry)}
                value={countries.find(country => country.value === selectedCountry)}
                onChange={handleChange}
                options={countries}
                styles={customStyles}
                formatOptionLabel={formatOptionLabel}//
            />
        </div>
        {selectedOption && (
            <div className=''>
                <a href={selectedOption.value} className="flex items-center justify-center text-purple-600 bg-gray-50 border-2 border-purple-600 hover:bg-purple-700  focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 w-14">
                <svg class="w-8 h-8 text-purple-600 hover:text-gray-50" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"/>
                </svg>
                </a>
            </div>
        )}
    </div>
    </>
  )
}

export default SelectFlags