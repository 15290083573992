import React from 'react'

const InputText = ({value, placeholder, change, type, disables, maxleng, textSize, error}) => {
  return (
    <input type={type || 'text'}
    value={value}
    onChange={change}
    className={` border ${error ? 'border-red-700 border-2 text-red-700 font-medium' : 'border-gray-300 ptext-gray-800'}   
    text-gray-800 text-sm rounded focus:ring-cyan-700 focus:border-cyan-700 
    block w-full p-2 font-light ${disables ? 'bg-gray-200' : 'bg-transparent'} `}
             placeholder={placeholder} {...(disables ? {disabled: true} : {})} maxLength={maxleng}  />
  )
}

export default InputText