import React, { useEffect, useRef, useState } from 'react'
import { ButtonSubmit, Buttons } from '../../Components/Button/Buttons';
import { InputCode } from 'verify-code-input-react';
import { useNavigate } from 'react-router-dom';
import AlertError from '../../Components/Alerts/AlertError';
import { useAppContext } from '../../Context/ApiProvider';
import { toast } from 'sonner';
import VerificationInput from "react-verification-input";
import { IoReload } from "react-icons/io5";

const ModalCodigo = ({ isOpen, onClose, children }) => {


  const Codref = useRef('');
  const navigate = useNavigate();
  const [errorCamposModal, setErrorCamposModal] = useState(false)
  const [mensajeError, setMensajeError] = useState('')
  const [tituloError, settituloError] = useState('')
  const [disabledButton, setdisabledButton] = useState(false)
  const [dsiabledReenvio, setdsiabledReenvio] = useState(false)
  const [verificationCode, setVerificationCode] = useState('');

  const {infoTelefono, VerificarCodigoProvider, obtenerCodigoProvider, dispatchInfoTelefono, dispatchNavegar} = useAppContext();

  useEffect(() => {
    setErrorCamposModal(false);
  }, []);

  const handleChangeCod = (value) => {
    setVerificationCode(value);
};
  


   const handleEnviarCodigo = async (e) => {
    setErrorCamposModal(false);
    setdisabledButton(true);
     e.preventDefault();

     if(verificationCode == ""){
     
       setErrorCamposModal(true);
       setMensajeError('Asegúrate de proporcionar la información requerida.');
       settituloError('Para continuar, Ingresa el codigo');
       setdisabledButton(false);
       return;
     }

     if(verificationCode.length < 6){
      
          setErrorCamposModal(true);
          setMensajeError('El codigo que has proporcionado es incorrecto');
          settituloError('Para continuar, Ingresa el codigo completo.');
          setdisabledButton(false);
          return
     }

     const data = {
         'code' : verificationCode,
         'target' : infoTelefono.target,
         'method' : infoTelefono.method
     };
  
     const resp = await VerificarCodigoProvider(data);
     console.log(resp)
     if(resp.statusText === 'OK'){
        navigate('/select-product');
        setdisabledButton(false);
     }else if(resp === 2){
        navigate('/create-client');
        setdisabledButton(false);
     }
     else{
        setErrorCamposModal(true);
        setMensajeError('Por favor verifique la información enviada');
        settituloError('Error al enviar el codigo');
        setdisabledButton(false);
     }

   }


   const handleReenviarCodigor = async () => {


        const data = {
            'target':  infoTelefono.target,
            'method' : infoTelefono.method
        }

        dispatchInfoTelefono({
            type: 'ADD_TELEFONO',
            value: data
         });
         setdsiabledReenvio(true);
         const resp = await obtenerCodigoProvider(data);
         if(resp.estatus === 200){
          setdsiabledReenvio(false);
          toast.success(`Codigo reenviado con éxito`, {
            style: {
                background: '#14532d',
                color:'#fff',
            },
            duration: 8000,
            });
            const data = {
                option : '1'
            }

            dispatchNavegar({
                type:'ADD_NAVEGAR',
                value: data
            });
         }
   };

   const handleSelecionarOtroMetodo =() => {
      onClose();
   }
  
  return (
    <>
      { isOpen && ( <form onSubmit={handleEnviarCodigo}>
            <div id="static-modal" className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="relative p-4 w-full max-w-2xl max-h-full animate__animated animate__bounceInUp">
                
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
                    
                    <div className="flex flex-col items-center justify-center p-4 md:p-5 space-y-2">
                        <h1 className='font-medium lg:text-lg'>Un codigo de verificación fue enviado al telefono  <span className='text-purple-600 font-bold'> {infoTelefono.target}</span> </h1>
                        <h4 className='font-medium'>Ingresa el código enviado a tu teléfono.</h4>
                        <VerificationInput classNames={{character: "character"}} inputProps={{ autoComplete: "one-time-code" }} onChange={handleChangeCod} />
                       
                        <p className='mt-10 font-medium text-gray-500'>El codigo tiene duración de 10 minutos</p>
                          <ButtonSubmit  size="w-full" type="submit"  nameEtiqueta="Verificar codigo"  disableButton={disabledButton} />
                    </div>
                    
                    <div className="flex flex-col items-center justify-center  p-4 md:p-5 border-t border-gray-200 rounded-b ">

                          <button type="button"  onClick={handleReenviarCodigor}
                          className={`py-2.5 px-5 me-2 text-md  font-medium text-purple-700  text-center bg-white lg:w-1/2
                           focus:z-10 focus:ring-4 focus:outline-none  flex items-center justify-center ${dsiabledReenvio ? 'cursor-not-allowed' : ''}`}
                          disabled={dsiabledReenvio ? 'disabled':''}>

                            { dsiabledReenvio ? (<svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                              </svg>) : <IoReload className='mr-2' />}
                              {!dsiabledReenvio ? 'Reenviar Codigo' : 'Espere un momento'} 
                          </button>
                       
                        <a onClick={handleSelecionarOtroMetodo} className='text-gray-700 underline font-light mt-4 text-md  cursor-pointer'>Seleccionar otro metodo para recibir mi codigo</a>
                        <div className='mt-5'>
                            {errorCamposModal ? (<AlertError   mensaje={mensajeError}
                                          titulo={tituloError}  />) : false}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
       </form>)}


    </>
  )
}

export default ModalCodigo