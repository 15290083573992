import React from 'react'
import FormularioComponent from '../../Components/ClientComponents/FormularioComponent'

const ClientPage = () => {
  return (
    <div className='py-6 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6'>
        
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">

            <FormularioComponent />

        </div>

    </div>
  )
}

export default ClientPage