import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../Context/ApiProvider';

const GrupoFictureQuery = ({titulo, texto, precio, id}) => {

    const navigate = useNavigate();
    const {dispatcQuerySelected, querys, dispatchNavegar} = useAppContext();
    
    const handleSubmit = () => {
        const searchQuery =  querys.find(query => query.id === id);
        dispatcQuerySelected({
            type:'SELECTED_QUERY',
            value: searchQuery
        });
        const data = {
            option : '3'
        }
        dispatchNavegar({
            type:'ADD_NAVEGAR',
            value: data
        });
        navigate('/select-product/horario');
    }

  return (
        <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 
        rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e animate__animated animate__fadeIn cursor-pointer shadow-lg"
        onClick={handleSubmit}>
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
                <h3 className="text-2xl font-medium text-gray-900 dark:text-white">{titulo}</h3>
                <p className="my-4 text-left text-gray-700">
                    {texto}
                </p>
            </blockquote>
            <figcaption className="flex items-center justify-center ">
                <h1 className='font-medium text-4xl text-purple-600'>${precio}</h1>
                
            </figcaption>    
        </figure>
  )
}

export default GrupoFictureQuery