import ConfirmarInformacionComponent from '../../Components/VerifyInformationClient/ConfirmarInformacionComponent'

const VerifyInformationClient = () => {
  return (
    <div className='py-3 px-4  space-y-12 max-w-screen-xl lg:space-y-10 sm:py-4 lg:px-6'>
        <h1 className='text-xl text-gray-800 font-medium text-left'>¡Por favor, verifica que los datos de tu consulta espiritual sean correctos! 

Persona que realizará la consulta </h1>
        <div className="border-t border-gray-300 my-4"></div>

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 sm:grid-cols-1 gap-4">

            <ConfirmarInformacionComponent />

        </div>

    </div>
  )
}

export default VerifyInformationClient