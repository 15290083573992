import React, { useEffect, useState } from 'react'
import { ButtonSubmit } from '../Button/Buttons';
import { useAppContext } from '../../Context/ApiProvider';
import AlertError from '../Alerts/AlertError';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Agregar los plugins utc y timezone a dayjs
dayjs.extend(utc);
dayjs.extend(timezone);


const ConfirmarInformacionComponent = () => {
    
  const { eventSelected, psychicSelected, querySelected, dispatchTerminos } = useAppContext();
  const [terminos, setTerminos] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [localTime, setLocalTime] = useState('');
  const [nyTime, setNyTime] = useState('');
  const [disabledButton, setDisabledButton] = useState(false)

  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  
  useEffect(() => {

    if( token === undefined || token === '' || token === null || token === null){
        navigate('/verificar');
    }else{
            const now = new Date();
            const fecha = new Date(eventSelected.startDate);
            const horal = fecha.toLocaleTimeString();
            const hzh = dayjs.tz.guess();
            const localTimeString = dayjs(eventSelected.startDate).utcOffset(-240).format('HH:mm A');
            setLocalTime(dayjs(eventSelected.startDate).format('HH:mm A'));
            const nyTimeString = dayjs.tz(eventSelected.startDate, 'Etc/GMT+4').format('HH:mm A');
            setNyTime(nyTimeString);
    }
  }, []);

  const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if(event.target.checked === false){
            setDisabledButton(false);
        }else{
            setDisabledButton(true);
        }
  }

  const handleSubmitTerminos = (event) => {
  
        event.preventDefault();
        if (isChecked === true){
            setTerminos('Aceptado');
            
            const data = {
                terminos : isChecked,
                terminosMessage : terminos
            }
            dispatchTerminos({
                type : 'ADD_TERMINOS',
                value : data
            });
            navigate('/select-product/card-informacion');
        }
  }
  

  return (
    <div>
         {/* <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2> Su hora:  
                    <span className='text-lg text-cyan-700 font-bold mr-10'> {localTime} (Hora de Miami) </span>  
                    Nuestra hora: <span className='text-lg text-amber-700 font-bold '>{nyTime}</span> 
                </h2>
                <hr className="my-2 border-t-1 border-gray-300" />
        </div> */}
        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2>{querySelected && querySelected.description} </h2>
        </div>

        <div className="text-gray-500 sm:text-lg dark:text-gray-400">
            <dl className=" text-gray-900 divide-y divide-gray-200 w-100">
                <div className="flex flex-col pb-1">
                    <dt className="lg:text-sm mb-1 text-gray-900 font-bold md:text-sm ">Fecha</dt>
                    <dd className="text-sm font-semibold">{eventSelected && dayjs(eventSelected.startDate).format('D/MMM/YYYY')} -  <span> Hora de consulta : {eventSelected.startHour} </span> </dd>
                </div>
                <div className="flex flex-col pb-1">
                    <dt className="lg:text-sm mb-1 text-gray-900 font-bold md:text-sm ">Persona que realizará la consulta </dt>
                    <dd className="text-sm font-semibold">{psychicSelected && psychicSelected.stage_name}</dd>
        
                </div>
                <div className="flex flex-col py-1">
                    <dt className="lg:text-sm mb-1 text-gray-900 font-bold md:text-sm ">Detalles de la cita</dt>
                    <dd className="text-lg font-light text-gray-599">
                        {querySelected && querySelected.observation}
                    </dd>
                </div>
                <div className="flex flex-col py-1">
                    <dt className="lg:text-sm mb-1 text-gray-900 font-bold md:text-sm ">Valor</dt>
                    <dd className="font-bold text-xl text-purple-600">
                        ${querySelected && querySelected.amount} USD
                    </dd>
                </div>
            </dl>
        </div>
        <div className='grid grid-col-1 mt-2'>
        <form onSubmit={handleSubmitTerminos}>
            <div className="flex items-center p-4 mb-4 text-sm text-purple-800 border border-purple-300 rounded-lg bg-green-50" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span className="sr-only">Advertencia</span>
                <div>
                    <span className="font-bold text-lg text-gray-700">Para tener en cuenta</span>
                    <p className='text-justify'>Estás a punto de agendar tu consulta espiritual. Te recomendamos que desde ya te programes para este momento, que requerirá de toda tu concentración y disposición para que puedas recibir esa ayuda que estás necesitando. Es importante tener en cuenta que, si no asistes a la reunión en la hora y fecha acordada, sin notificar con, al menos 24 horas de anticipación, no serás elegible para recibir un reembolso del pago realizado para dicha sesión. Esto ayuda a garantizar que nuestros recursos se utilicen de manera eficiente y que podamos mantener la calidad y la planificación de nuestros servicios.
                    </p>
                    <br />
                    <p className='text-justify'>
                    Apreciamos tu comprensión y compromiso para asistir a tu consulta espiritual en la hora y fecha programada. Si tiene alguna duda sobre esta póliza o necesita reprogramar tu consulta, no dudes en contactar a nuestro equipo de Servicio al Cliente. Tu satisfacción es nuestra prioridad, y nuestra misión espiritual es acompañarte en cada parte del proceso. 
                    </p>
                    <div className="flex items-center mb-4 mt-5">
                            <input id="checkTerminos" type="checkbox" value="1" onChange={handleCheckboxChange}
                            className="w-4 h-4 text-green-600 bg-purple-100 border-purple-700 rounded focus:ring-purple-500 focus:ring-2 " />
                            <label htmlfor="checkTerminos" className="ms-2 text-sm font-medium text-gray-700"> Acepto los Términos y condiciones de Nino Prodigio  </label>
                    </div>
                </div>
            </div>

            <div className="text-gray-500 sm:text-lg dark:text-gray-400 mx-auto text-center mb-5">
                        {disabledButton === true ? (<ButtonSubmit  size="w-52" type="" nameEtiqueta="Continuar" disableButton={false} />) : false}
            </div>
        </form>
        </div>
    </div>
  )
}

export default ConfirmarInformacionComponent