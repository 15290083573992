import { createContext, useContext, useReducer, useState } from "react";
import {isPossiblePhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber  } from 'libphonenumber-js';
import { obtenerCodigo, 
         verificarCodigo, 
         getInfoPsychic, 
         getInfoQuery, 
         getInfoDateCalendar, 
         postCreateClient, 
         getSelectMeeting,
         postMeetingToken,
         getInfoUser,
         getSelecCustomer,
         postCreateCustomerStripe,
         postMeeting,
         patchUpdateClient,
         getViweHours,
         getMeetingToken,
         getMeetingTokenProvisional,
         patchReagendarCliente } from "../Services/ApiServiceHttp";

import { getCountries, getCountrieUbication } from "../Services/ApiServiceExternos";

import "dayjs/locale/es";
import { useNavigate } from "react-router-dom";

const AppContext = createContext();

const useAppContext = () => {
    return useContext(AppContext);
}

const initialInfoTelefono = {
     telefono : []
};

const initialInfoUsers = {
    users : []
}

const initialInfoPsychic = {
    psychics : [],
}

const initialInfoPsychicSelected = {
    psychicSelect : []
}

const initialInfoQuery = {
    querys : []
}

const initialInfoQuerySelected = {
    querySelect : []
}

const initialDateCalendar = {
    dates : []
}

const initialHourCalendar = {
    hours : []
}

const initialInfoEventSelected = {
    dateSelected : []
};

const initialInfoTerminos = {
    terminos : []
}

const initialInfoCard = {
    card : []
}

const initialNavegarOption = {
    optionNavegar : []
}

const initialCurrentPage = {
    currentPage : []
}

const inititalInfoCountries = {
    countries : []
};

const initialcountrieUbication = {
    countrieUbication : []
}

const initialInfoReagendar = {
    infoClientReagendar : []
}

const initialInfoConfirmacionnavegar = {
    infoNavegar : []
}


const reducerInfoTel = (state, action) => {
        
    switch (action.type) {
         case 'ADD_TELEFONO': {
             return {
                 ...state,
                 telefono: action.value
             }
         }
     }
     return state;
 }

 const reducerInfoUsers = (state, action) => {
    switch (action.type) {
        case 'ADD_USERS': {
            return {
                ...state,
                users: action.value
            }
        }
        case 'UPDATE_USER': {
            return {
                ...state,
                users: action.value
            }
        }
    }
    return state;
 }

 const reducerInfoPsychic = (state, action) => {
    switch (action.type) {
        case 'ADD_PSYCHIC': {
            return {
                ...state,
                psychics: action.value
            }
        }
    }
    return state;
 }

 const reducerInfoPsychicSelected = (state, action) => {
    switch (action.type) {
        case 'SELECTED_PSYCHIC' : {
            return {
                ...state,
                psychicSelect: action.value
            }
        }
        case 'REMOVE_PSYCHIC' : {
            return {
                ...state,
                psychicSelect: []
            }
        }   
    }
    return state;
 }

 const reducerInfoQuery = (state, action) => {

    switch (action.type) {
        case 'ADD_QUERY': {
            return {
                ...state,
                querys : action.value
            }
        }  
    }

    return state;
 }

 const reducerInfoQuerySelected = (state, action) => {

    switch (action.type) {
        case 'SELECTED_QUERY': {
            return {
                ...state,
                querySelect: action.value
            }
        }
        case 'REMOVE_QUERY' : {
            return {
                ...state,
                querySelect: []
            }
        }

    }
    return state;
 }

 const reducerInfoCalendar = (state, action) => {

    switch (action.type) {
        case 'ADD_CALENDAR': {
            return {
                ...state,
                dates: action.value
            }
        }
    }
    return state;
 }

 const reducerInfoEventSelected = (state, action) => {

    switch (action.type) {
        case 'ADD_SELECTED_EVENT': {
            return {
                ...state,
                dateSelected : action.value
            }
        }
    }
    return state;
 }

 const reducerInfoTerminos = (state, action) => {
    switch (action.type) {
        case 'ADD_TERMINOS': {
            return {
                ...state,
                terminos : action.value
            }
        }
       
    }
    return state;
 }

 const reducerInfoCard = (state, action) => {
    switch (action.type){
        case 'ADD_CARD': {
            return {
            ...state,
            card: action.value
            }
        }
    }
    return state;
 }
 
const reducerNavegar = (state, action) => {
    switch (action.type){
        case 'ADD_NAVEGAR': {
            return {
            ...state,
            optionNavegar: action.value
            }
        }
    }
    return state;
}

const reducerHourCalendar = (state, action) => {
    switch (action.type){
        case 'ADD_HOURS': {
            return {
            ...state,
            hours: action.value
            }
        }
    }
    return state;
}

const reducerCurrentPage = (state, action) => {
    switch (action.type){
        case 'ADD_CURRENTPAGE': {
            return {
            ...state,
            currentPage: action.value
            }
        }
    }
    return state;
}

const reducerCountries = (state, action) => {
    switch (action.type){
        case 'ADD_COUNTRIES': {
            return {
            ...state,
            countries: action.value
            }
        }
    }
    return state;
}

const reducerContrieUbication = (state, action) => {
    switch (action.type){
        case 'ADD_COUNTRIE_UBICATION': {
            return {
            ...state,
            countrieUbication: action.value
            }
        }
    }
    return state;
}

const reducerInfoCLienteReagendar = (state, action) => {
    switch (action.type){
        case 'ADD_USER_REAGENDAR': {
            return {
            ...state,
            infoClientReagendar: action.value
            }
        }
    }
    return state;
}

const reducerInfoNavegar = (state, action) => {
    switch (action.type){
        case 'ADD_CONFIRM_NAVEGAR': {
            return {
            ...state,
            infoNavegar: action.value
            }
        }
    }
    return state;
}

const ApiProvider = ({children}) => {
    
    const [stateInfoTelefono, dispatchInfoTelefono] = useReducer(reducerInfoTel, initialInfoTelefono);
    const [stateUser, dispatchUser] = useReducer(reducerInfoUsers, initialInfoUsers);
    const [statePsychic, dispatcePsychic] = useReducer(reducerInfoPsychic, initialInfoPsychic);
    const [statePsychicSelected, dispatcePsychicSelected] = useReducer(reducerInfoPsychicSelected, initialInfoPsychicSelected);
    const [stateQuery, dispatceQuery] = useReducer(reducerInfoQuery, initialInfoQuery);
    const [stateQuerySelected, dispatcQuerySelected] = useReducer(reducerInfoQuerySelected, initialInfoQuerySelected);
    const [stateCalendar, dispatceCalendar] = useReducer(reducerInfoCalendar, initialDateCalendar);
    const [stateEventCalendar, dispatchEventCalendar] = useReducer(reducerInfoEventSelected, initialInfoEventSelected);
    const [stateTerminos, dispatchTerminos] = useReducer(reducerInfoTerminos, initialInfoTerminos);
    const [stateCard, dispatchCard] = useReducer(reducerInfoCard, initialInfoCard);
    const [stateNavegar, dispatchNavegar] = useReducer(reducerNavegar, initialNavegarOption);
    const [stateHours, dispatchHours] = useReducer(reducerHourCalendar, initialHourCalendar);
    const [stateCurrentPage, dispatchCurrentPage] = useReducer(reducerCurrentPage, initialCurrentPage);
    const [stateCountries, dispatchCountries] = useReducer(reducerCountries, inititalInfoCountries);
    const [stateCountrieUbication, dispatchCountrieUbication] = useReducer(reducerContrieUbication, initialcountrieUbication);
    const [stateUserReagendar, dispatchUserReagendar] = useReducer(reducerInfoCLienteReagendar, initialInfoReagendar);
    const [stateConfirNavegar, dispatchConfirNavegar] = useReducer(reducerInfoNavegar, initialInfoConfirmacionnavegar);
    
    //reducerCountries
    //initialHourCalendar
    
    const navigate = useNavigate();

    // Metodoss
    const obtenerCodigoProvider = async (param) => {
        if(param.target && isPossiblePhoneNumber(param.target)){
            const respuesta = await obtenerCodigo(param);
            console.log(respuesta)

            if(respuesta.status === 200){
                return {message: respuesta.data.message, estatus : respuesta.status}
            }else{
                return {message: 'Ha ocurrido un error', estatus : respuesta.status}
            }

        }else{
            return  {message: 'Número invalido', estatus : 401};
        }  
    }

    const VerificarCodigoProvider = async (param) => {

        const respuesta = await verificarCodigo(param);
        console.log(respuesta)
        if(respuesta.status === 200){

            if(respuesta.data.user){
                sessionStorage.setItem('token', respuesta.data.token);

                const dataUser = {
                    'client_id': respuesta.data.user.uuid
                }
                dispatchCountrieUbication({
                    type : 'ADD_COUNTRIE_UBICATION',
                    value : {
                        country : respuesta.data.user.country
                    }
                  });
                const respUsers = await getInfoUser(dataUser, sessionStorage.getItem('token'));
               

                    dispatchUser({
                        type:'ADD_USERS',
                        value:respUsers.data
                    });
                    
                    return respuesta;

            }else{
                sessionStorage.setItem('token_client', respuesta.data.token);
                return 2;
            }

        }else{
            return 0
        }
    }

    const getInfoPsychicProvider =  async() => {

        const resp = await getInfoPsychic(sessionStorage.getItem('token'));
        dispatcePsychic({
            type:'ADD_PSYCHIC',
            value:resp.data
        });
        return resp;
    }

    const getInfoQueryProvider =  async(psychic_id) => {

        
        const data = {
            'psychic_id' : psychic_id,
        };

        const resp = await getInfoQuery(data,sessionStorage.getItem('token'));
        dispatceQuery({
            type:'ADD_QUERY',
            value:resp.data
        });
        return resp;
    }

    const getInfoDateCalendarProvider = async(startDate, psychic, currentPage, timezonaCliente, limite) => {

        const data = {
            'startDate' : startDate,
            'psychic_id' : psychic,
            'page' : currentPage,
            'limit' : limite
        };
        const resp = await getInfoDateCalendar(data, sessionStorage.getItem('token'));
        dispatceCalendar({
            type:'ADD_CALENDAR',
            value: resp.data
        });
        dispatchCurrentPage({
            type:'ADD_CURRENTPAGE',
            value: resp.data.pagination
        })
        getViweHoursProvider(Object.keys(resp.data.data)[0], psychic, timezonaCliente);
    }

    const postCreateClientProvider = async(param) => {

        const resp = await postCreateClient(param, sessionStorage.getItem('token_client'));
        
        if(resp.data.user){
            sessionStorage.setItem('token', resp.data.token);

            const dataUser = {
                'client_id': resp.data.user.uuid
            }
            const respUsers = await getInfoUser(dataUser, sessionStorage.getItem('token'));

            dispatchUser({
                type:'ADD_USERS',
                value:respUsers.data
            });
            
            sessionStorage.removeItem('token_client');
            return 1;
        }else{
            return 0;
        }
    }

    const patchUpdateClientProvider = async(param) => {
        const resp = await patchUpdateClient(param);
        return resp;
    }

    const getSelectMeetingProvider = async(param) => {
        const resp = await getSelectMeeting(param, sessionStorage.getItem('token'));
        return resp;
    }

    const postMeetingTokenProvider = async(param) => {

        const resp = await postMeetingToken(param, sessionStorage.getItem('token'));
        return resp.data;
    };

    const getSelectCustomerProvider = async(param) => {
       
        const resp = await getSelecCustomer(param, sessionStorage.getItem('token'));
        return resp;
    }

    const postCreateCustomerStripeProvider = async(param) => {
        const resp = await postCreateCustomerStripe(param, sessionStorage.getItem('token'));
        return resp;
    }

    const handleCerrarSession = () => {
        navigate('/');
        sessionStorage.removeItem('token');
    }

    const postMeetingProvider = async(param) => {
        const resp = await postMeeting(param, sessionStorage.getItem('token'));
        return resp;
    }

    const getViweHoursProvider = async(date, psychic, timezonaCliente) => {
        const param = {
            'date' : date,
            'psychic_id' : psychic,
            'timezone_client' : timezonaCliente
        }
        const resp = await getViweHours(param);
          dispatchHours({
            type: 'ADD_HOURS',
            value: resp.data.available_days
        }); 
        return resp;
    }

    const getgetCountriesProvider = async() => {
        const resp = await getCountries();
        const regionesAFiltrar = ["Americas" , "Europe" ];
        const filtrarRegion = resp.filter(region => regionesAFiltrar.includes(region.region));
        dispatchCountries({
            type:'ADD_COUNTRIES',
            value : filtrarRegion
        })
        return resp;
    }

    const getCountrieUbicationProvider = async() => {
        const resp = await getCountrieUbication();
        return resp;
    }

    const getMeetingTokenProvider = async(code) => {
        const resp = await getMeetingToken(code);
        dispatchUserReagendar({
            type:'ADD_USER_REAGENDAR',
            value : resp
        });
        return resp;
    }

    const getMeetingTokenProvisionalProvider = async(code) => {
        const resp = await getMeetingTokenProvisional(code);
        if (resp.data && resp.data.token) {
            sessionStorage.setItem('token', resp.data.token);
        }
        return resp;
    }

    const patchReagendarClienteProvider = async(param, id) => {
        const resp = await patchReagendarCliente(param, id);
        return resp;
    }

  return (
        <AppContext.Provider
           value={{
             infoTelefono : stateInfoTelefono.telefono,
             dispatchInfoTelefono,
             obtenerCodigoProvider,
             VerificarCodigoProvider,
             infoUsers : stateUser.users,
             getInfoPsychicProvider,
             psychis:statePsychic.psychics,
             dispatcePsychicSelected,
             psychicSelected : statePsychicSelected.psychicSelect,
             getInfoQueryProvider,
             dispatceQuery,
             querys : stateQuery.querys,
             dispatcQuerySelected,
             querySelected : stateQuerySelected.querySelect,
             getInfoDateCalendarProvider,
             eventCalendar : stateCalendar.dates,
             dispatchEventCalendar,
             eventSelected : stateEventCalendar.dateSelected,
             dispatchTerminos,
             postCreateClientProvider,
             dispatchCard,
             dispatchNavegar,  optionNav : stateNavegar.optionNavegar,
             getSelectMeetingProvider,
             postMeetingTokenProvider,
             handleCerrarSession,
             getSelectCustomerProvider,
             postCreateCustomerStripeProvider,
             postMeetingProvider,
             patchUpdateClientProvider,
             dispatchUser,
             getViweHoursProvider,
             horas : stateHours.hours, 
             dispatchHours,
             currentpagePro : stateCurrentPage.currentPage, 
             dispatchCurrentPage,
             getgetCountriesProvider,
             countriesAPI : stateCountries.countries,
             getCountrieUbicationProvider,
             getMeetingTokenProvider,
             dispatchCountrieUbication,
             countrieSelected : stateCountrieUbication.countrieUbication,
             getMeetingTokenProvisionalProvider,
             patchReagendarClienteProvider,
             UserReagendado : stateUserReagendar.infoClientReagendar,
             dispatchConfirNavegar,
             ventaConfirmada : stateConfirNavegar.infoNavegar
           }}
        >
                {children}
        </AppContext.Provider>
  )
}

export {ApiProvider, useAppContext}