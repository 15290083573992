import React from 'react';
import fotovictor from '../../assets/victor_foto.png'
import fondo from '../../assets/fondo.png'
import logo from '../../assets/logo-blanco.png'
const Hero = () => {

return (


<div className='relative bg-cover bg-center bg-no-repeat'
    style={{ backgroundImage: `url(${fondo})` }}>
    <section className=' py-6 px-2 mx-auto space-y-12 max-w-screen-xl lg:space-y-10 sm:py-16 lg:px-6'>
        <div className='grid grid-col-1 p-0'>
            <div className='flex justify-center sm:justify-start mb-6 md:mb-0'>
                <a href="https://agenda.ninoprodigio.com/" className="flex items-center">
                    <img src={logo} className="mr-3 h-30" alt="Nino Prodigio Logo" />  
                </a>
            </div>
            <div className="md:flex md:items-center md:justify-between md:space-x-5">
                <div className="text-center sm:text-center md:text-left mr-auto place-self-center lg:col-span-8">
                    <h1 className="max-w-2xl mb-4 text-3xl font-bold tracking-tight md:text-4xl xl:text-5xl text-gray-200">Descubre Tu Destino y Transforma Tu Vida con <span className="font-bold underline underline-offset-3 decoration-8 decoration-gray-100 ">El Niño Prodigio</span></h1>
                    <p className="max-w-2xl mb-6 font-light text-gray-50 lg:mb-8 md:text-lg lg:text-xl">Haz parte de la gran comunidad que ha encontrado una guía y ayuda espiritual de la mano de Victor Florencio, el astrólogo y vidente más reconocido en los Estados Unidos y comunidad hispanoamericana. Prepárate para transformar tu vida. Agenda tu consulta virtual o presencial y embárcate en tu viaje hacia la renovación personal con el <span className='font-bold'>Niño Prodigio</span> o con <span className='font-bold'>Agustina</span>.</p>
                    <div className="mt-6 flex flex-col sm:flex-row md:mt-0 justify-stretch sm:justify-end md:justify-start space-y-4 sm:space-y-0 sm:space-x-3 md:space-x-3">
                    <a href="https://agenda.ninoprodigio.com/verificar"className=" w-full inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-xl  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 bg-gradient-purple border border-gray-300">
                        <svg className="w-[26px] h-[26px] text-white  mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Zm3-7h.01v.01H8V13Zm4 0h.01v.01H12V13Zm4 0h.01v.01H16V13Zm-8 4h.01v.01H8V17Zm4 0h.01v.01H12V17Zm4 0h.01v.01H16V17Z"/>
                        </svg>
                        Agenda tu consulta con Niño Prodigio o Agustina
                    </a>
                    <a href="https://minutos.ninoprodigio.com/"
                        className="w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-50 border border-gray-300 rounded-xl hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 bg-gradient-purple ">
                    <svg className="w-[26px] h-[26px] text-gray-50  mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        Compra Paquetes de Minutos Con uno de mis psíquicos
                    </a> 
        </div>
                    <div className="text-center sm:text-center md:text-left lg:flex">
                    <p className='mt-2 text-gray-700'>También puedes comunicarte con su equipo de psíquicos expertos a través de la Línea Espiritual<a href="tel:18004110112" className="hover:underline sm:inline">1-800-411-0112</a></p>
                </div>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-4 lg:flex">
                    <img src={fotovictor} alt="Foto Victor "/>
                </div>    
                

            </div>
        </div>

    </section>
</div>
)
}

export default Hero